import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// const app = firebase.app();
const auth = firebase.auth();
const db = firebase.firestore();
const now = firebase.firestore.Timestamp.now;
const functions = firebase.functions();
const storage = firebase.storage();
export { auth, db, now, firebase, functions, storage };

if (process.env.REACT_APP_USE_EMULATORS) {
  functions.useEmulator('localhost', 5001);
  auth.useEmulator('http://localhost:9099');
  db.useEmulator('localhost', 8080);
} else if (process.env.REACT_APP_FUNC_EMULATOR) {
  functions.useEmulator('localhost', 5001);
}

const firebaseContext = {
  auth,
  db,
  now,
  firebase,
  firestore: firebase.firestore,
  functions,
  storage,
};

export default firebaseContext;
