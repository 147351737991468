import { Typography, Button } from 'antd';

import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';

import { ReportsSection } from './ReportsSection';

export const ClientDetails = () => {
  const { client, setDrawerView } = useDrawerContext();
  return (
    <div className="drawer-section">
      <div className="drawer-section-heading">
        <Typography.Title level={3}>{client.businessName}</Typography.Title>
        <Button
          type="ghost"
          size="small"
          className="ml-auto"
          onClick={() => setDrawerView('chat')}>
          Chat
        </Button>
      </div>
      <ReportsSection />
    </div>
  );
};
