export const Bubble = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#clip0)">
        <mask id="path-1-inside-1" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 4C1.34315 4 0 5.34315 0 7V17C0 18.6569 1.34378 20 3.00064 20H14H18.9994H24L22 16.6666V7C22 5.34315 20.6569 4 19 4H3Z"
          />
        </mask>
        <path
          d="M24 20V21.5H26.6493L25.2863 19.2283L24 20ZM22 16.6666H20.5V17.0821L20.7138 17.4384L22 16.6666ZM1.5 7C1.5 6.17157 2.17157 5.5 3 5.5V2.5C0.514719 2.5 -1.5 4.51472 -1.5 7H1.5ZM1.5 17V7H-1.5V17H1.5ZM3.00064 18.5C2.17179 18.5 1.5 17.828 1.5 17H-1.5C-1.5 19.4857 0.515769 21.5 3.00064 21.5V18.5ZM14 18.5H3.00064V21.5H14V18.5ZM14 21.5H18.9994V18.5H14V21.5ZM18.9994 21.5H24V18.5H18.9994V21.5ZM25.2863 19.2283L23.2862 15.8949L20.7138 17.4384L22.7138 20.7717L25.2863 19.2283ZM20.5 7V16.6666H23.5V7H20.5ZM19 5.5C19.8284 5.5 20.5 6.17157 20.5 7H23.5C23.5 4.51472 21.4853 2.5 19 2.5V5.5ZM3 5.5H19V2.5H3V5.5Z"
          fill="currentColor"
          mask="url(#path-1-inside-1)"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};
