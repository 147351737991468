import { Button, Popconfirm, Popover } from 'antd';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import { CANDIDATE_STATUS } from '@yougig/shared/candidates/constants';
import { useAuth } from '@yougig/shared/auth/AuthProvider';
import services from '@yougig/shared/utils/services';

import { StatusPopoverForm } from './StatusPopoverForm';
import { Delete } from '../../icons';

const CustomButton = styled(Button)`
  position: absolute;
  bottom: 35px;
`;

export const StatusPopover = forwardRef(
  (
    {
      profile,
      children,
      allJobs,
      assignedJobs,
      candidate,
      isChecklistCompleted = true,
      menuGroups,
      onRemove,
      ...props
    },
    ref,
  ) => {
    const { userRef } = useAuth();
    const [visiblePopover, setPopoverVisibility] = useState(false);
    const formikRef = useRef();

    const allJobsOptions = useMemo(
      () =>
        allJobs?.map(({ key, name }) => ({ value: key, label: name })) || [],
      [allJobs],
    );

    const onDelete = useCallback(async () => {
      await candidate.remove();
      setPopoverVisibility(false);
      onRemove && onRemove();
    }, [candidate, setPopoverVisibility, onRemove]);

    const handleApply = useCallback(
      async ({ jobs, status, ...dismissData }) => {
        const client = allJobs.find((j) => j.id === jobs[0])?.client;
        const context = {
          userRef,
          profile,
          client,
        };
        if (status === CANDIDATE_STATUS.DISMISSED) {
          // we are checking the status here to handle the archive separately
          // in order to not add additional parameters for `setStatusAndSave` method.
          await candidate.archive(dismissData, context);
        } else {
          await candidate.setStatusAndSave(status, context);
        }
        const selectedJobs = allJobs.filter((job) => jobs.includes(job.key));
        await candidate.removeMissingAssignments(selectedJobs);
        await candidate.addAssignments(selectedJobs);
      },
      [candidate, userRef, profile, allJobs],
    );

    useImperativeHandle(ref, () => ({
      changeStatus: (nextStatus) => {
        if (
          assignedJobs.length === 1 &&
          nextStatus !== CANDIDATE_STATUS.DISMISSED
        ) {
          handleApply({ status: nextStatus, jobs: assignedJobs });
        } else {
          setPopoverVisibility(true);
          setTimeout(() => {
            if (formikRef.current) {
              formikRef.current.setFieldValue('status', nextStatus);
            }
          }, 0);
        }
      },
    }));

    return (
      <Popover
        placement="topRight"
        trigger="click"
        overlayClassName="job-status-popover"
        openClassName="ant-btn-expanded"
        visible={visiblePopover}
        onVisibleChange={setPopoverVisibility}
        content={
          <>
            <StatusPopoverForm
              assignedJobs={assignedJobs}
              isChecklistCompleted={isChecklistCompleted}
              menuGroups={menuGroups}
              candidate={candidate}
              allJobsOptions={allJobsOptions}
              handleApply={handleApply}
              formikRef={formikRef}
              hidePopover={() =>
                setPopoverVisibility(false)
              }></StatusPopoverForm>
            {services.get('config').PROJECT === 'manager' && (
              <Popconfirm
                title="Are you sure you want to remove this candidate from the client (and all its jobs)? This cannot be undone."
                placement="topLeft"
                trigger="click"
                onConfirm={onDelete}>
                <CustomButton
                  type="ghost"
                  size="small"
                  danger
                  icon={<Delete />}
                />
              </Popconfirm>
            )}
          </>
        }
        getPopupContainer={(trigger) => trigger.parentElement}
        {...props}>
        {children}
      </Popover>
    );
  },
);
