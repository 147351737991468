import { useRef } from 'react';
import { Formik } from 'formik';
import { Form, Input, Radio } from 'formik-antd';
import { Button } from 'antd';

import {
  UPDATES_AUTHOR,
  UPDATES_AUTHOR_OPTIONS,
  UPDATES_TYPE,
} from '@yougig/shared/updates/constants';
import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { schema } from '@yougig/shared/updates/schema';
import { Update } from '@yougig/shared/updates/Update';
import {
  DatePicker,
  dateFormatter,
} from '@yougig/ui/components/form/DatePicker';
import { ClientAutoCompleteField } from '@yougig/ui/components/form/ClientAutoComplete';

export const UpdateForm = ({ parentRef, type, client, closeForm }) => {
  const { userRef } = useAuth();

  const initialValues = useRef({
    date: new Date(),
    authorType: UPDATES_AUTHOR.MANAGER,
    message: '',
    client,
  });
  console.log('initial values', initialValues.current);

  return (
    <div className="updates-form">
      <Formik
        initialValues={initialValues.current}
        validationSchema={schema}
        onSubmit={async (values) => {
          await Update.create(parentRef, { ...values, createdBy: userRef });
          closeForm();
        }}>
        {({ handleSubmit, values, setFieldValue }) => (
          <Form onSubmit={handleSubmit} layout="vertical">
            <div className="row">
              <Form.Item name="date" label="Date" className="col-6">
                <DatePicker
                  name="date"
                  placeholder="Select Date"
                  onChange={(value) => setFieldValue('date', value)}
                  value={values.date}
                  format={dateFormatter}
                />
              </Form.Item>
              <Form.Item name="authorType" label="Author" className="col-6">
                <Radio.Group
                  name="authorType"
                  options={UPDATES_AUTHOR_OPTIONS.filter((o) =>
                    o.applicable.includes(type),
                  )}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
              <Form.Item name="message" label="Message" className="col-12">
                <Input.TextArea
                  name="message"
                  placeholder="Write here"
                  autoSize
                />
              </Form.Item>
              {type === UPDATES_TYPE.TALENT && (
                <Form.Item
                  name="client"
                  label="Related Client"
                  className="col-6">
                  <ClientAutoCompleteField name="client" />
                </Form.Item>
              )}
            </div>
            <div className="updates-form--footer">
              <Button type="ghost" onClick={closeForm}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Add Update
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
