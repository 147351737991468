import { Form, Input, Radio } from 'formik-antd';
import { useFormikContext } from 'formik';

import {
  REQUEST_AUTHOR_OPTIONS,
  REQUEST_TYPES,
} from '@yougig/shared/actions/constants';
import { useAuth } from '@yougig/shared/auth/AuthProvider';

import { ClientAutoCompleteField } from '../form/ClientAutoComplete';
import { DatePicker, TimePicker } from '../form/DatePicker';
import { AttachmentsField } from '../form/AttachmentsField';

export const RequestFormFields = ({ requestType }) => {
  const { isAdmin, isRecruiter } = useAuth();
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      {(isAdmin || isRecruiter) && (
        <div className="row">
          <Form.Item name="authorType" label="Author" className="col-6">
            <Radio.Group
              name="authorType"
              options={REQUEST_AUTHOR_OPTIONS}
              optionType="button"
              buttonStyle="solid"
              value={values.authorType}
            />
          </Form.Item>
          <Form.Item name="client" label="Client" className="col-6">
            <ClientAutoCompleteField name="client" />
          </Form.Item>
        </div>
      )}
      <div className="row">
        <Form.Item
          name="dateTime"
          label={requestType === REQUEST_TYPES.INFO ? 'Due date' : 'Date'}
          className="col-6">
          <DatePicker
            value={values.dateTime}
            onChange={(date) => setFieldValue('dateTime', date)}
          />
        </Form.Item>
        <Form.Item
          name="dateTime"
          label={requestType === REQUEST_TYPES.INFO ? 'Due time' : 'Time'}
          className="col-6">
          <TimePicker
            value={values.dateTime}
            use12Hours
            format="h:mm a"
            onChange={(date) => setFieldValue('dateTime', date)}
          />
        </Form.Item>
        <Form.Item label="Details" name="notes" className="col-12">
          <Input.TextArea name="notes" placeholder="Feedback or other notes" />
        </Form.Item>
      </div>
      <Form.Item name="attachments">
        <AttachmentsField name="attachments" label="Attachments" />
      </Form.Item>
    </>
  );
};
