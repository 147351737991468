import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { UserMenuDropdownWithModal } from '@yougig/ui/components/common/UserMenuWithModal';
import { HeaderLayout } from '@yougig/ui/components/layout/Header';

import { Menu } from './Menu';

const Logo = styled.img`
  height: 44px;
`;

const links = [
  { title: 'Clients', link: '/clients' },
  { title: 'Jobs', link: '/jobs' },
  { title: 'Candidates', link: '/candidates' },
];

export const Header = () => {
  const { isAuthenticated, user } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const headerRef = useRef();
  useEffect(() => headerRef.current.closeMobileMenu(), [location]);

  return (
    <HeaderLayout
      ref={headerRef}
      logo={
        <Link to="/">
          <Logo src="/assets/images/logo.svg" alt="Yougig Logo" />
        </Link>
      }
      userMenu={
        <UserMenuDropdownWithModal
          user={user}
          onConfirm={() => history.push('/')}
        />
      }>
      {isAuthenticated && <Menu items={links} />}
    </HeaderLayout>
  );
};
