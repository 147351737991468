import { COLORS } from '@yougig/shared/constants';

export const Comment = ({
  outlined = false,
  color = COLORS.TEXT,
  ...props
}) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      {!outlined && (
        <path
          d="M19 3.5L5 20.514 6 21l3 1 3.25-1h6.25l3-1 1.5-2V5.5l-4-2z"
          fill="#D8E6FC"
        />
      )}
      <path
        d="M.75 7A3.25 3.25 0 014 3.75h16A3.25 3.25 0 0123.25 7v10A3.25 3.25 0 0120 20.25h-6.343c-1.26 0-2.468.5-3.359 1.391l-1.121 1.122a.25.25 0 01-.354 0L7.702 21.64a4.75 4.75 0 00-3.359-1.391H4A3.25 3.25 0 01.75 17V7z"
        stroke={color}
        strokeWidth={1.5}
      />
      <circle cx={7} cy={9} r={1} fill={color} />
      <circle cx={7} cy={12} r={1} fill={color} />
      <circle cx={7} cy={15} r={1} fill={color} />
      <path
        d="M17.5 9h-8M17.5 12h-8M17.5 15h-8"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
