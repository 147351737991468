import { useMemo, useState } from 'react';
import { Typography, Form, Radio } from 'antd';
import styled from 'styled-components';
import { useInfiniteQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import formatDate from 'date-fns/format';
import parseDate from 'date-fns/parseISO';
import addMonths from 'date-fns/addMonths';

import { useApi } from '@yougig/shared/api/Api';
import {
  PROJECT_TERMS_OPTIONS,
  PROJECT_STATUS_OPTIONS,
  PROJECT_STATUS,
} from '@yougig/shared/projects/constants';
import { WORK_FORMAT_OPTIONS } from '@yougig/shared/constants';
import { Table } from '@yougig/ui/components/elements/Table';
import { InputSearch } from '@yougig/ui/components/elements/InputSearch';

import { getSourceName } from '../../utils/sources';

const AssignmentTitle = styled.div`
  & > p {
    flex: 1;
    margin-bottom: 0;
  }
`;

const columns = [
  {
    title: 'Job',
    dataIndex: 'title',
    render: (title, { id, external_id, status, quantity }) => {
      return (
        <AssignmentTitle>
          <p>
            <Link to={`/jobs/${external_id || id}`}>
              {quantity > 1 && `${quantity} × `}
              {title}
            </Link>
            {status === PROJECT_STATUS.ARCHIVED && (
              <>
                <br />
                <span className="info">
                  {PROJECT_STATUS_OPTIONS.getLabel(status)}
                </span>
              </>
            )}
          </p>
        </AssignmentTitle>
      );
    },
  },
  {
    title: 'Client',
    dataIndex: 'company_name',
  },
  {
    title: 'Source',
    dataIndex: 'source',
    render: (source) => (
      <span className="info">{getSourceName(source.collection)}</span>
    ),
  },
  {
    title: 'Date',
    className: 'cell-date',
    dataIndex: 'due_date',
    render: (due, { posted_date }) => {
      return (
        <>
          {!!due && (
            <>
              <span className="info">Due </span>
              {formatDate(parseDate(due), 'MMM d')}
            </>
          )}
          {!!due && !!posted_date && <br />}
          {!!posted_date && (
            <>
              <span className="info">Posted </span>
              {formatDate(parseDate(posted_date), 'MMM d')}
            </>
          )}
        </>
      );
    },
  },
  {
    title: 'Period',
    key: 'period',
    render: (_, { start_date, duration }) => (
      <>
        {!!start_date && formatDate(parseDate(start_date), 'MMM d')}
        {!!start_date && !!duration && <br />}
        {!!start_date &&
          !!duration &&
          formatDate(addMonths(parseDate(start_date), duration), 'MMM d')}
        {!start_date && !!duration && `${duration} months`}
      </>
    ),
  },
  {
    title: 'Location',
    dataIndex: 'country_code',
    render: (country_code, { work_format, terms }) => {
      const value = [
        country_code,
        work_format && WORK_FORMAT_OPTIONS.getLabel(work_format),
        terms && PROJECT_TERMS_OPTIONS.getLabel(terms),
      ]
        .filter(Boolean)
        .join(',\n');

      return <span style={{ whiteSpace: 'pre-wrap' }}>{value}</span>;
    },
  },
];

const TYPE_FILTER_OPTIONS = [
  { value: 'ongoing', label: 'Ongoing' },
  { value: 'expired', label: 'Expired' },
];

export const Jobs = () => {
  const history = useHistory();
  const { paginatedQueryFn } = useApi();
  const [filters, setFilters] = useState({ type: 'ongoing' });
  const queryParams = useMemo(
    () => ({
      keywords: filters.keywords,
      expired: filters.type === 'expired',
      job_type: 'position',
    }),
    [filters],
  );

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
    ['/assignments', queryParams],
    paginatedQueryFn,
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.next ? allPages.length : undefined,
    },
  );
  const dataSource = useMemo(
    () => data?.pages.flatMap((i) => i.result) || [],
    [data],
  );

  return (
    <>
      <div className="page-heading">
        <Typography.Title>Jobs</Typography.Title>
        <Form.Item>
          <Radio.Group
            value={filters.type}
            options={TYPE_FILTER_OPTIONS}
            onChange={(e) => setFilters({ ...filters, type: e.target.value })}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        <InputSearch
          onSearch={(keywords) => setFilters({ ...filters, keywords })}
        />
      </div>
      <InfiniteScroll
        initialLoad={false}
        threshold={600}
        loadMore={() => fetchNextPage()}
        hasMore={hasNextPage}>
        <Table
          loading={isLoading}
          columns={columns}
          rowKey={(entry) => entry.id}
          dataSource={dataSource}
          pagination={false}
          onRow={({ id }) => {
            return {
              onDoubleClick: () => {
                history.push(`/jobs/${id}`);
              },
            };
          }}
        />
      </InfiniteScroll>
    </>
  );
};
