import { useCallback, useEffect, useState } from 'react';
import { Button, Divider, Typography, Tabs, Dropdown, Menu } from 'antd';

import { Bubble, Edit, Lock, MoreDots } from '@yougig/ui/icons';
import { AttachmentsList } from '@yougig/ui/components/elements/AttachmentsList';
import { TalentClientInfo } from '@yougig/ui/components/talents/TalentClientInfo';
import { TalentInternalInfoBlock } from '@yougig/ui/components/talents/TalentInternalInfoBlock';
import { TalentProfileLayout } from '@yougig/ui/components/talents/TalentProfileLayout';
import { SkillsList } from '@yougig/ui/components/common/SkillsList';
import { useTalentDrawerContext } from '@yougig/ui/components/talents/TalentDrawerContext';
import { NotesField } from '@yougig/ui/components/talents/NotesField';
import { ExperienceInfo } from '@yougig/ui/components/talents/ExperienceInfo';
import { UPDATES_TYPE } from '@yougig/shared/updates/constants';
import { PrescreeningScores } from '@yougig/ui/components/common/PrescreeningScores';
import { TalentProfileReports } from '@yougig/ui/components/reports/TalentProfileReports';
import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { useImpersonate, impersonate } from '@yougig/ui/utils/useImpersonate';

import { TalentActions } from './TalentActions';
import { Updates } from '../common/updates/Updates';
import { TalentProfileRequests } from './TalentProfileRequests';
import { useJob } from '../../pages/jobs/JobContext';
import { TalentClientsTab } from './TalentClientsTab';

const TalentDropdownMenu = ({ profile }) => {
  const { isAdmin } = useAuth();
  const uid = profile.account?.id;
  const impersonate = useImpersonate(uid, process.env.REACT_APP_TALENT_APP_URL);

  if (!uid || !isAdmin) {
    return null;
  }

  return (
    <Dropdown
      type="ghost"
      placement="bottomRight"
      trigger="click"
      overlayClassName="ant-dropdown--fixed"
      overlay={
        <Menu>
          <Menu.Item key="impersonate" onClick={impersonate}>
            Impersonate
          </Menu.Item>
        </Menu>
      }>
      <Button
        type="ghost"
        size="large"
        icon={<MoreDots />}
        onClick={(e) => e.stopPropagation()}
      />
    </Dropdown>
  );
};

export const TalentProfile = () => {
  const { job, updateCandidateInList } = useJob();
  const { entryKey, profile, setDrawerView } = useTalentDrawerContext();
  const [activeTabKey, setActiveTabKey] = useState('updates');

  const onClickImpersonate = useCallback(async () => {
    const teamUsers =
      profile?.team &&
      (await profile.team
        .collection('teamUsers')
        .where('role', '==', 'owner')
        .get());

    const teamOwner = teamUsers?.docs?.[0].get('user')?.id;

    teamOwner && impersonate(teamOwner, process.env.REACT_APP_TALENT_APP_URL);
  }, [profile?.team]);

  useEffect(() => {
    profile ? setActiveTabKey('updates') : setActiveTabKey('experience');
  }, [profile]);

  const saveNotes = useCallback(
    async (value) => {
      await profile.save({ notes: value });
      updateCandidateInList({ profile }, entryKey);
    },
    [profile, entryKey, updateCandidateInList],
  );

  return (
    <TalentProfileLayout>
      <div className="drawer-section">
        <TalentProfileLayout.Header
          profile={profile}
          actions={
            <>
              {!!profile.account && (
                <Button
                  type="ghost"
                  size="large"
                  icon={<Bubble />}
                  onClick={() => setDrawerView('chat')}
                />
              )}
              <Button
                type="ghost"
                size="large"
                icon={<Edit />}
                onClick={() =>
                  setDrawerView(profile ? 'editCandidate' : 'createCandidate')
                }
              />
              <TalentDropdownMenu profile={profile} />
            </>
          }
        />

        <TalentClientInfo profile={profile} />

        {!!profile.attachments?.length && (
          <TalentProfileLayout.Row>
            <AttachmentsList items={profile.attachments} />
          </TalentProfileLayout.Row>
        )}
      </div>
      <TalentInternalInfoBlock
        profile={profile}
        name={profile.company?.name || profile.fullName}
        location={profile.company?.location}
        onClickImpersonate={profile?.team && onClickImpersonate}>
        <NotesField value={profile.notes} onSave={saveNotes} />
      </TalentInternalInfoBlock>
      <div className="drawer-section">
        {!!profile.ref && <TalentProfileRequests />}
        {!!profile.ref && (
          <TalentProfileReports
            profile={profile}
            onReportClick={(report) => {
              setDrawerView('report', { report });
            }}
          />
        )}
        <Typography.Title level={4}>Skills</Typography.Title>
        {!!profile.prescreening?.length && (
          <PrescreeningScores items={profile.prescreening} />
        )}
        <SkillsList skills={profile.skills} highlighted={job?.skills} />
        <Divider />
        <TalentProfileLayout.Tabs
          activeKey={activeTabKey}
          onChange={(activeKey) => setActiveTabKey(activeKey)}>
          {!!profile && (
            <Tabs.TabPane
              key="updates"
              tab={
                <>
                  <Lock outlined={activeTabKey === 'updates'} />
                  Updates
                </>
              }>
              {!!profile?.ref && (
                <Updates
                  parentRef={profile.ref}
                  type={UPDATES_TYPE.TALENT}
                  client={job?.client}
                />
              )}
            </Tabs.TabPane>
          )}
          {!!profile.ref && (
            <Tabs.TabPane
              key="clients"
              tab={
                <>
                  <Lock outlined={activeTabKey === 'clients'} />
                  Clients
                </>
              }>
              <TalentClientsTab />
            </Tabs.TabPane>
          )}
          <Tabs.TabPane tab="Work Experience" key="experience">
            {profile.experience_info?.details
              ?.sort((a, b) => b.start_year - a.start_year)
              .map((info) => (
                <ExperienceInfo key={info.organization} {...info} />
              )) || null}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Education & Training"
            key="education"></Tabs.TabPane>
          <Tabs.TabPane tab="Portfolio" key="portfolio"></Tabs.TabPane>
          <Tabs.TabPane tab="Assignments" key="assignments"></Tabs.TabPane>
        </TalentProfileLayout.Tabs>
      </div>
      {!!job && <TalentActions />}
    </TalentProfileLayout>
  );
};
