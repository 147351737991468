import { Button, Collapse, Typography } from 'antd';
import { Form, Input, Select, Switch } from 'formik-antd';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { ArrowBottom, Cross } from '@yougig/ui/icons';
import { REGIONS_OPTIONS } from '@yougig/shared/constants';
import { CountryField } from '@yougig/ui/components/form/CountryField';
import { SourceField } from '@yougig/ui/components/form/SourceField';
import { ScoringSlider } from '@yougig/ui/components/form/ScoringSlider';
import { CandidateTypeRadio } from '@yougig/ui/components/form/CandidateTypeRadio';
import services from '@yougig/shared/utils/services';

import { useDrawerContext } from '../common/DrawerProvider';

const SearchFormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0 30px;
  margin-bottom: auto;
  > * {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 200px;
    max-width: 300px;
    @media (max-width: 767px) {
      max-width: none;
    }
  }
  .form-item__available {
    display: flex;
    flex-direction: row;
    align-items: center;
    .ant-form-item-label {
      padding: 0;
    }
    .ant-form-item-control {
      margin-left: auto;
      flex-grow: 0;
      &-input {
        min-height: auto;
        .ant-switch-checked {
          background-color: var(--c_green);
        }
      }
    }
  }
  .ant-form-item:last-child {
    margin-bottom: 0;
  }
`;

export const GlobalSearchForm = () => {
  const { values, resetForm } = useFormikContext();
  const { setDrawerView } = useDrawerContext();
  const isSourceField = services.get('config').PROJECT === 'manager';
  return (
    <div className="drawer-section">
      <Form layout="vertical">
        <div className="page-heading">
          <Typography.Title level={3}>Talent Pool Search</Typography.Title>
        </div>
        <SearchFormRow>
          <div>
            <Collapse expandIcon={ArrowBottom} defaultActiveKey={['keywords']}>
              <Collapse.Panel key="keywords" header="Keywords">
                <Form.Item name="exact_keywords" label="Must-have keywords">
                  <Input name="exact_keywords" placeholder="Type here" />
                </Form.Item>

                <Form.Item name="keywords" label="Nice-to-have keywords">
                  <Input name="keywords" placeholder="Type here" />
                </Form.Item>
              </Collapse.Panel>
              <Collapse.Panel key="seniority_gte" header="Seniority">
                <ScoringSlider
                  name="seniority_gte"
                  value={values.seniority_gte}
                />
              </Collapse.Panel>
            </Collapse>
          </div>
          <div>
            <Collapse expandIcon={ArrowBottom}>
              <Collapse.Panel key="location" header="Location">
                <Form.Item name="regions" label="Talent Region">
                  <Select
                    showArrow
                    allowClear
                    mode="multiple"
                    name="regions"
                    options={REGIONS_OPTIONS}
                    suffixIcon={<ArrowBottom width={16} height={16} />}
                    clearIcon={<Cross width={16} height={16} />}
                    placeholder="Select Region"
                    getPopupContainer={(node) => node.parentElement}
                  />
                </Form.Item>
                <Form.Item name="country" label="Country">
                  <CountryField name="country" showSearch />
                </Form.Item>
              </Collapse.Panel>
              <Collapse.Panel key="verification" header="Verification">
                <Form.Item name="candidateType">
                  <CandidateTypeRadio name="candidateType" type="filter" />
                </Form.Item>
              </Collapse.Panel>
            </Collapse>
          </div>
          <div>
            <Collapse expandIcon={ArrowBottom}>
              <Collapse.Panel key="knowledge" header="Knowledge areas">
                <ScoringSlider
                  name="scoring_technical_gte"
                  label="Technical"
                  value={values.scoring_technical_gte}
                />
                <ScoringSlider
                  name="scoring_business_gte"
                  label="Business"
                  value={values.scoring_business_gte}
                />
                <ScoringSlider
                  name="scoring_creative_gte"
                  label="Design"
                  value={values.scoring_creative_gte}
                />
              </Collapse.Panel>
              {isSourceField && (
                <Collapse.Panel key="source" header="Source">
                  <SourceField name="source" />
                </Collapse.Panel>
              )}
            </Collapse>
            <Form.Item
              name="only_available"
              label="Available only"
              className="form-item__available">
              <Switch name="only_available" size="small" />
            </Form.Item>
          </div>
        </SearchFormRow>
        <div className="form-actions">
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
          <Button
            type="ghost"
            htmlType="button"
            onClick={() => {
              resetForm();
              setDrawerView('filter');
            }}>
            Discard
          </Button>
        </div>
      </Form>
    </div>
  );
};
