import { Formik } from 'formik';
import isToday from 'date-fns/isToday';
import formatDate from 'date-fns/format';
import { Form } from 'formik-antd';
import { Button } from 'antd';

import { DatePicker } from '@yougig/ui/components/form/DatePicker';
import { reportPeriodSchema } from '@yougig/shared/reports/schema';
import { ReportsService } from '@yougig/shared/reports/ReportsService';

import { useDrawerContext } from '../common/DrawerProvider';

const initialValues = {
  startDate: new Date(),
  endDate: new Date(),
};

const customFormat = (value) =>
  isToday(new Date(value)) ? 'Today' : formatDate(value, 'yyyy-MM-dd');

export const ReportDateForm = ({ onClose }) => {
  const { client, setDrawerView } = useDrawerContext();
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={reportPeriodSchema}
        onSubmit={async (
          { startDate, endDate },
          { setSubmitting, resetForm },
        ) => {
          const report = await ReportsService.createDraftReport(
            client.__ref,
            startDate,
            endDate,
          );
          setSubmitting(false);
          resetForm(initialValues);
          onClose();
          setDrawerView('report', { report });
        }}>
        {({ handleSubmit, values, setFieldValue, isSubmitting }) => {
          return (
            <Form onSubmit={handleSubmit} layout="vertical">
              <Form.Item name="startDate" label="From">
                <DatePicker
                  name="startDate"
                  placeholder="Select Date"
                  onChange={(value) => setFieldValue('startDate', value)}
                  value={values.startDate}
                  format={customFormat}
                />
              </Form.Item>

              <Form.Item name="endDate" label="To">
                <DatePicker
                  name="endDate"
                  placeholder="Select Date"
                  onChange={(value) => setFieldValue('endDate', value)}
                  value={values.endDate}
                  format={customFormat}
                />
              </Form.Item>
              <div className="ant-popover--footer">
                <Button
                  type="ghost"
                  size="small"
                  disabled={isSubmitting}
                  onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="small"
                  loading={isSubmitting}
                  htmlType="submit">
                  Next
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
