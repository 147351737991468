import { Form as AntForm, Collapse, Divider, Button } from 'antd';
import { Form, Input } from 'formik-antd';
import { useFormikContext } from 'formik';

import { PhoneField } from '@yougig/ui/components/form/PhoneField';
import { AttachmentsField } from '@yougig/ui/components/form/AttachmentsField';
import { ArrowBottom, Skills, Location, Availability } from '@yougig/ui/icons';
import { CandidateTypeRadio } from '@yougig/ui/components/form/CandidateTypeRadio';
import { LinksField } from '@yougig/ui/components/form/LinksField';
import { Attachments } from '@yougig/shared/common/Attachments';
import { LocationLanguagesFields } from '@yougig/ui/components/talents/LocationLanguagesFields';
import { AvailabilityFields } from '@yougig/ui/components/talents/AvailabilityFields';
import { SkillsToolsField } from '@yougig/ui/components/form/SkillsToolsField';
import {
  mainSchema,
  locationLanguagesSchema,
  skillsSchema,
  availabilitySchema,
} from '@yougig/shared/talents/schemas';
import { TeamField } from '@yougig/ui/components/form/TeamField';
import { CV_STATUS } from '@yougig/shared/talents/constants';

import { ScoringField } from './ScoringField';

export const schema = mainSchema
  .concat(locationLanguagesSchema)
  .concat(skillsSchema)
  .concat(availabilitySchema);

export const transformFormPayload = ({
  internalLinks,
  internalAttachments,
  attachments,
  verification,
  internalRate,
  manual_scoring,
  ...values
}) => {
  values.vetted = verification === 'vetted' || verification === 'proven';
  values.worked_with_us = verification === 'proven';

  values.languages = values.languages?.filter((l) => !!l.language) || [];
  values.internalLinks = internalLinks?.filter((l) => !!l.url) || [];

  values.internalAttachments =
    internalAttachments?.map(Attachments.fromRcUpload) || [];
  values.attachments = attachments?.map(Attachments.fromRcUpload) || [];

  values.internalRate = parseInt(internalRate) || null;

  values.manual_scoring = manual_scoring
    ? {
        creative: parseInt(manual_scoring.creative) || 0,
        technical: parseInt(manual_scoring.technical) || 0,
        business: parseInt(manual_scoring.business) || 0,
      }
    : null;

  values.status = CV_STATUS.PUBLISH;

  return values;
};

export const CandidateForm = ({
  candidate,
  onCancel,
  submitLabel = 'Save',
}) => {
  const { isSubmitting } = useFormikContext();
  return (
    <Form layout="vertical">
      <div className="row">
        <Form.Item name="firstName" label="First Name" className="col-6">
          <Input name="firstName" placeholder="First Name" />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name" className="col-6">
          <Input name="lastName" placeholder="Last Name" />
        </Form.Item>
        <Form.Item name="title" label="Position Title" className="col-6">
          <Input name="title" placeholder="Title" />
        </Form.Item>
        <Form.Item name="internalRate" label="Internal Rate" className="col-6">
          <Input name="internalRate" placeholder="XX" prefix="$" />
        </Form.Item>
        <Form.Item name="email" label="Email" className="col-6">
          <Input name="email" placeholder="email@example.com" />
        </Form.Item>
        <PhoneField label="Mobile phone" name="phone" className="col-6" />
        <TeamField className="col-6" />
        <AntForm.Item label="Internal Links" className="col-12 links--field">
          <LinksField name="internalLinks" />
        </AntForm.Item>
        <AttachmentsField
          name="internalAttachments"
          className="col-6"
          label="Internal Attachments"
        />
        <AttachmentsField
          name="attachments"
          className="col-6 form-item--warning"
          label="Client Attachments"
        />
      </div>
      <Collapse expandIcon={ArrowBottom} expandIconPosition="right">
        <Collapse.Panel
          header={
            <>
              <Location width={24} height={24} outlined={false} />
              Location & Languages
            </>
          }>
          <LocationLanguagesFields />
        </Collapse.Panel>
      </Collapse>
      <Collapse expandIcon={ArrowBottom} expandIconPosition="right">
        <Collapse.Panel
          header={
            <>
              <Skills />
              Skills & Seniority
            </>
          }>
          <div className="row">
            <SkillsToolsField name="skills" label="Skills / Tools" />
          </div>
          <Divider />
          <div className="row">
            <ScoringField
              scoring={candidate?.scoring}
              className="col-12"
              name="manual_scoring"
            />
          </div>
          <Divider />
          <div className="row">
            <Form.Item
              name="verification"
              label="Verification"
              className="col-6 mb-40">
              <CandidateTypeRadio name="verification" />
            </Form.Item>
          </div>
        </Collapse.Panel>
      </Collapse>
      <Collapse expandIcon={ArrowBottom} expandIconPosition="right">
        <Collapse.Panel
          header={
            <>
              <Availability />
              Availability
            </>
          }
          className="mb-32">
          <AvailabilityFields />
        </Collapse.Panel>
      </Collapse>
      <Form.Item name="notes" label="Notes">
        <Input.TextArea
          name="notes"
          placeholder="Make some notes..."
          autoSize={{ minRows: 2, maxRows: 10 }}
        />
      </Form.Item>
      <AntForm.Item className="form-actions">
        <Button type="ghost" htmlType="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          className="ml-auto"
          loading={isSubmitting}>
          {submitLabel}
        </Button>
      </AntForm.Item>
    </Form>
  );
};
