import { useCallback, useMemo } from 'react';
import { Form } from 'formik-antd';
import { Select } from 'antd';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useField } from 'formik';

import services from '@yougig/shared/utils/services';

import { ArrowBottom, Cross } from '../../icons';

export const TeamField = ({
  name = 'team',
  className,
  label = 'Team',
  ...props
}) => {
  const [field, , { setValue }] = useField(name);
  const [teams] = useCollectionData(services.get('db').collection('teams'), {
    idField: 'id',
  });
  const options = useMemo(
    () =>
      teams
        ? teams.map(({ id, legalName }) => ({ value: id, label: legalName }))
        : [],
    [teams],
  );
  const value = useMemo(() => field.value?.id || '', [field.value]);
  const handleChange = useCallback(
    (value) => {
      setValue(value ? services.get('db').doc(`teams/${value}`) : null);
    },
    [setValue],
  );

  return (
    <Form.Item name={name} label="Team" className={className}>
      <Select
        name={name}
        options={options}
        allowClear
        showSearch
        showArrow
        suffixIcon={<ArrowBottom width={16} height={16} />}
        clearIcon={<Cross width={16} height={16} />}
        placeholder="Select a team"
        value={value}
        onChange={handleChange}
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        {...props}
      />
    </Form.Item>
  );
};
