import { useTalentDrawerContext } from './TalentDrawerContext';
import { TalentTable } from './TalentTable';
import { baseColumns } from './baseColumns';

export const columnsTalent = [
  baseColumns.candidate,
  baseColumns.location,
  baseColumns.occupation,
  baseColumns.scoring,
  baseColumns.rate,
  baseColumns.availability,
];

export const TalentTableWithDrawer = ({ columns, ...props }) => {
  const { entryKey, setDrawerView } = useTalentDrawerContext();

  return (
    <TalentTable
      columns={columns || columnsTalent}
      selectedRowKey={entryKey}
      onTalentSelect={(record) =>
        setDrawerView('profile', {
          candidate: record.candidate,
          profile: record.profile,
          entryKey: record.key,
        })
      }
      {...props}
    />
  );
};
