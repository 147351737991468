import { useMemo } from 'react';
import { Form, Select } from 'formik-antd';
import { useQuery } from 'react-query';

import { ArrowBottom, Cross } from '@yougig/ui/icons';

export const SourceField = ({ name, ...props }) => {
  const { data: sources } = useQuery(['candidates', 'sources']);

  const sourceOptions = useMemo(
    () =>
      sources?.map((source) => ({
        value: source.collection,
        label: `${source.collection} (${source.count})`,
      })),
    [sources],
  );

  return (
    <Form.Item name={name}>
      <Select
        allowClear
        showArrow
        showSearch
        name={name}
        options={sourceOptions}
        suffixIcon={<ArrowBottom width={16} height={16} />}
        clearIcon={<Cross width={16} height={16} />}
        placeholder="All"
        getPopupContainer={(node) => node.parentElement}
        {...props}
      />
    </Form.Item>
  );
};
