import { Drawer } from '../elements/Drawer';
import { useTalentDrawerContext } from './TalentDrawerContext';

export const TalentDrawer = ({ children }) => {
  const { viewMode, closeDrawer } = useTalentDrawerContext();

  return (
    <Drawer
      className="drawer-no-padding"
      visible={!!viewMode}
      onClose={closeDrawer}>
      {children}
    </Drawer>
  );
};
