import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Button, Row, Typography } from 'antd';
import styled from 'styled-components';
import addHours from 'date-fns/addHours';
import startOfTomorrow from 'date-fns/startOfTomorrow';

import {
  REQUEST_AUTHOR,
  REQUEST_STATUS,
  REQUEST_STATUS_OPTIONS,
  REQUEST_TYPES,
} from '@yougig/shared/actions/constants';
import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { Attachments } from '@yougig/shared/common/Attachments';
import { RequestService } from '@yougig/shared/actions/RequestService';
import { schema } from '@yougig/shared/actions/schema';
import services from '@yougig/shared/utils/services';

import { useTalentDrawerContext } from './TalentDrawerContext';
import { RequestFormFields } from '../elements/RequestFormFields';
import { StatusLabel } from '../elements/Status';
import { StatusDropdown } from '../elements/StatusDropdown';

const TOMORROW = addHours(startOfTomorrow(new Date()), 7);

export const LabelWrapper = styled.div`
  margin-left: 16px;
  margin-top: 5px;
`;

export const RequestForm = () => {
  const { profile, request, job, setDrawerView } = useTalentDrawerContext();
  const { userRef } = useAuth();

  return (
    <div className="drawer-section">
      <Formik
        initialValues={{
          dateTime: request?.dateTime || TOMORROW,
          notes: request?.notes || '',
          authorType: request?.authorType || REQUEST_AUTHOR.CLIENT,
          attachments: request?.attachments.map(Attachments.toRcUpload) || [],
          client: request ? request.client : job?.client || null,
          status: request?.status || REQUEST_STATUS.SUBMITTED,
          profile: profile?.__ref || null,
        }}
        validationSchema={schema}
        onSubmit={async (values) => {
          const attachments = values.attachments.map(Attachments.fromRcUpload);
          if (profile.isDraft) {
            await profile.save();
          }
          await (
            request || RequestService.createRequest(profile, userRef)
          ).save({ ...values, attachments });

          setDrawerView('profile', { request: null });
        }}>
        {({ values, isSubmitting, setFieldValue, handleSubmit, dirty }) => (
          <>
            <Form layout="vertical" onSubmit={handleSubmit}>
              <Row align="top">
                <Typography.Title level={3}>
                  {request && 'Edit '}Request
                </Typography.Title>
                {services.get('config').PROJECT === 'manager' ? (
                  <StatusDropdown
                    name="status"
                    options={REQUEST_STATUS_OPTIONS}
                    className="ml-auto"
                  />
                ) : (
                  <LabelWrapper>
                    <StatusLabel
                      {...REQUEST_STATUS_OPTIONS.get(values.status)}
                    />
                  </LabelWrapper>
                )}
              </Row>
              <RequestFormFields requestType={REQUEST_TYPES.INFO} />
              <Row justify="end">
                {services.get('config').PROJECT === 'manager' ? (
                  <Button type="ghost" htmlType="submit" loading={isSubmitting}>
                    Save
                  </Button>
                ) : (
                  <Button
                    type="ghost"
                    htmlType="submit"
                    loading={isSubmitting}
                    onClick={() => {
                      values.status === REQUEST_STATUS.RETURNED &&
                        setFieldValue(
                          'status',
                          dirty
                            ? REQUEST_STATUS.SUBMITTED
                            : REQUEST_STATUS.CONFIRMED,
                        );
                    }}>
                    {values.status === REQUEST_STATUS.RETURNED
                      ? dirty
                        ? 'Submit'
                        : 'Confirm'
                      : 'Save'}
                  </Button>
                )}
              </Row>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};
