import { Typography } from 'antd';
import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  padding: 6px 0;
  min-height: 56px;
  margin-bottom: -56px;
  .ant-typography {
    margin: 0 auto 0 0;
  }
  @media (max-width: 991px) {
    .ant-btn {
      > span {
        display: none;
      }
      &.icon-left > svg {
        margin-right: 0;
      }
    }
    .ant-form-item {
      min-width: auto;
      max-width: 350px;
    }
  }
  @media (max-width: 767px) {
    gap: 10px;
  }
`;

const StickyExtra = styled(Header)`
  position: sticky;
  top: 18px;
  z-index: 30;
  margin-bottom: 24px;
  pointer-events: none;
  & > *:first-child {
    margin-left: auto;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    height: 18px;
    background-color: #fff;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
  }
  > * {
    pointer-events: auto;
  }
  > .ant-form-item {
    max-width: 275px;
  }
  @media (max-width: 767px) {
    top: 70px;
  }
`;

export const StickyPanel = {
  TitleContainer: Header,
  StickyExtra,
};
