import { Typography, Button, Space } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import formatDistance from 'date-fns/formatDistance';
import { useMemo, useRef } from 'react';

import { Lock, Save } from '@yougig/ui/icons';
import { ReportsService } from '@yougig/shared/reports/ReportsService';
import { timesheetSchema } from '@yougig/shared/reports/schema';
import { Report } from '@yougig/ui/components/reports/Reports';
import {
  TimesheetTable,
  columns as basicColumns,
} from '@yougig/ui/components/reports/TimesheetTable';

export const ReportTimesheetSection = ({ report, disableEditing = false }) => {
  const formikRef = useRef();
  const timesheetEnabled = ReportsService.useTimesheetEnabled(report.client);
  const { timeentries, totalBillableDuration, lastSync, refresh, syncing } =
    ReportsService.useExtendedReportTimesheet(report);

  const initialValues = useMemo(
    () => ({
      timeentries: timeentries.map(({ billableDuration = '', id }) => ({
        billableDuration,
        id,
      })),
    }),
    [timeentries],
  );

  const columns = useMemo(
    () =>
      basicColumns.concat({
        dataIndex: 'billableDuration',
        title: 'Billable',
        width: 100,
        className: 'ant-table-cell-editable',
        onHeaderCell: () => {
          return {
            onClick: () => {
              if (formikRef.current) {
                const newTimeEntries = timeentries.map((o) => ({
                  ...o,
                  billableDuration: o.duration,
                }));
                formikRef.current.setFieldValue(`timeentries`, newTimeEntries);
              }
            },
          };
        },
        render: (value, _, index) => (
          <Form.Item
            name={`timeentries[${index}].billableDuration`}
            help={null}>
            <Input
              name={`timeentries[${index}].billableDuration`}
              type="number"
              disabled={disableEditing}
              min={0}
              max={16}
              step={0.1}
            />
          </Form.Item>
        ),
      }),
    [disableEditing, timeentries, formikRef],
  );

  const totalHours = useMemo(() => {
    return timeentries
      .reduce((acc, cur) => {
        return acc + cur.duration;
      }, 0)
      .toFixed(1);
  }, [timeentries]);

  return (
    <div className="drawer-section drawer-section-lock">
      <Lock className="drawer-section__icon" />
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={timesheetSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await ReportsService.extendReportTimesheet(
            report,
            values.timeentries,
          );
          setSubmitting(false);
        }}>
        {({ isSubmitting }) => (
          <Form>
            <div className="section--heading">
              <Typography.Title level={4}>
                <Space>
                  <span>Timesheet</span>
                  {!!totalHours && (
                    <Typography.Text type="secondary">
                      {totalHours}h (billable: {totalBillableDuration})
                    </Typography.Text>
                  )}
                </Space>
              </Typography.Title>
              <Space className="ml-auto">
                <Typography.Text type="secondary">
                  {lastSync
                    ? `Updated 
            ${formatDistance(lastSync, new Date(), {
              addSuffix: true,
            })} `
                    : 'Update'}
                  {timesheetEnabled && (
                    <Button
                      type="link"
                      htmlType="button"
                      size="small"
                      onClick={() => refresh()}
                      icon={<Report.RefreshIcon $loading={syncing} />}
                    />
                  )}
                </Typography.Text>
                {!!timeentries?.length && (
                  <Typography.Text type="secondary">
                    Save
                    <Button
                      type="link"
                      htmlType="submit"
                      disabled={isSubmitting || disableEditing}
                      size="small"
                      icon={<Save width={12} height={12} />}
                    />
                  </Typography.Text>
                )}
              </Space>
            </div>

            <TimesheetTable
              dataSource={timeentries}
              pagination={false}
              columns={columns}
              rowKey={(row) => row.name}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};
