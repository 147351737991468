import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

/**
 * @typedef {object} TalentDrawerContextValue
 * @prop {string} viewMode - Drawer's view mode.
 * @prop {setDrawerViewCallback} setDrawerView
 * @prop {() => void} closeDrawer
 * @prop {import('@yougig/shared/talents/Profile').Profile} [profile] — Talent profile if provided
 * @prop {import('@yougig/shared/candidates/Candidate').Candidate} [candidate] - Candidate instance
 * @prop {import('@yougig/shared/actions/Request').Request} [request] — Request in editing mode.
 * @prop {string} [entityKey] - Key for the entry (either candidate.id or profile.key)
 */

export const TalentDrawerContext = createContext({
  viewMode: null,
  setDrawerView: () => {
    console.warn(
      'Maybe you forgot to wrap your component with TalentProfileProvider',
    );
  },
  closeDrawer: () => {},
});

export const TalentDrawerProvider = ({ job, children }) => {
  const [viewMode, setViewMode] = useState();
  /**
   * @typedef {Object} ContextData
   * @prop {import('@yougig/shared/talents/Profile').Profile} [profile]
   * @prop {import('@yougig/shared/candidates/Candidate').Candidate} [candidate]
   */
  /**
   * @type {[ContextData?, Function]} contextDataState
   */
  const [contextData, setContextData] = useState();
  const [profile, setProfile] = useState(contextData?.profile);

  useEffect(() => {
    if (contextData?.profile?.ref) {
      return contextData.profile.ref.onSnapshot((snap) => {
        const firebaseProfile = snap.data();
        if (firebaseProfile) {
          firebaseProfile.apiData = contextData.profile.apiData;
          setProfile(firebaseProfile);
        } else {
          setProfile(contextData.profile);
          console.warn(`Missing profile with ID ${snap.id}`);
        }
      });
    } else {
      setProfile(contextData?.profile);
    }
  }, [contextData?.profile]);

  const setDrawerView = useCallback(
    (viewMode = null, newData = {}) => {
      setViewMode(viewMode);
      setContextData({ ...contextData, ...newData });
    },
    [setViewMode, setContextData, contextData],
  );

  const closeDrawer = useCallback(() => {
    setViewMode('');
    setContextData();
  }, [setViewMode]);

  const contextValue = useMemo(
    () => ({
      ...contextData,
      profile,
      job,
      viewMode,
      setDrawerView,
      closeDrawer,
    }),
    [profile, job, viewMode, setDrawerView, closeDrawer, contextData],
  );

  return (
    <TalentDrawerContext.Provider value={contextValue}>
      {children}
    </TalentDrawerContext.Provider>
  );
};

export const useTalentDrawerContext = () => useContext(TalentDrawerContext);
