import { Button, Col, Collapse, Typography } from 'antd';
import { Checkbox, Form, Input, Select } from 'formik-antd';
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';

import {
  CANDIDATE_STATUS,
  CANDIDATE_STATUS_GROUPS,
  CANDIDATE_STATUS_OPTIONS,
  REASONS,
} from '@yougig/shared/candidates/constants';
import { array, object, string } from '@yougig/shared/utils/validation';

import { StatusList } from '../elements/StatusList';
import { Arrow, ArrowBottom, Cross } from '../../icons';

const ButtonGroup = styled.div`
  margin-top: 24px;
  display: flex;
  column-gap: 16px;
  & > .ant-btn + .ant-btn {
    margin-left: 0;
  }
`;

const CheckboxGroup = styled(Checkbox.Group)`
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  .ant-checkbox {
    &-wrapper {
      margin: 3px 0;
    }
    & + span {
      text-decoration: line-through;
      color: var(--c_text);
    }
    &-checked + span {
      text-decoration: none;
      color: var(--c_primary);
    }
  }
`;

const JobsSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    border-radius: 3px;
  }
  margin: 12px 0 24px 0;
`;

const ReasonSelect = styled(Select)`
  margin-top: 10px;
`;

const reasonOptions = REASONS.map((value) => ({ value, label: value }));

const schema = object({
  jobs: array().of(string()),
  status: string().required(),
  reason: string().nullable().when('status', {
    is: CANDIDATE_STATUS.DISMISSED,
    then: string().nullable().required(),
  }),
  notes: string().when('status', {
    is: CANDIDATE_STATUS.DISMISSED,
    then: string().required(),
  }),
});

export const StatusPopoverForm = ({
  menuGroups,
  isChecklistCompleted,
  assignedJobs,
  candidate,
  formikRef,
  allJobsOptions,
  handleApply,
  hidePopover,
}) => {
  const [selectedJobs, setSelectedJobs] = useState(
    allJobsOptions.filter(({ value }) => assignedJobs.includes(value)),
  );

  useEffect(() => {
    setSelectedJobs(
      allJobsOptions.filter(({ value }) => assignedJobs.includes(value)),
    );
  }, [allJobsOptions, setSelectedJobs, assignedJobs]);

  return (
    <div
      onClick={(e) => {
        // we need to stop propagate on click events outside the popover content
        // to avoid handling the click on the table row.
        e.stopPropagation();
      }}>
      <Formik
        initialValues={{
          jobs: assignedJobs,
          status: candidate?.status,
          reason: candidate?.reason || null,
          notes: candidate?.notes || '',
        }}
        innerRef={formikRef}
        validationSchema={schema}
        enableReinitialize
        onSubmit={(values) => {
          handleApply(values);
          hidePopover();
        }}>
        {({ values, setFieldValue }) => (
          <Form>
            <Collapse
              accordion
              defaultActiveKey={['statuses']}
              expandIcon={() => <Arrow />}>
              <Collapse.Panel
                key="jobs"
                header={`Jobs (${selectedJobs.length})`}>
                <Col key="jobs" className="mb-8">
                  <Form.Item name="jobs" className="mb-0">
                    <CheckboxGroup
                      name="jobs"
                      className="mb-0"
                      layout="vertical"
                      options={selectedJobs}
                    />
                  </Form.Item>
                  <JobsSelect
                    placeholder="Add job"
                    getPopupContainer={(el) => el.parentElement}
                    value={null}
                    options={allJobsOptions.filter(
                      ({ value }) =>
                        !selectedJobs.find((job) => job.value === value),
                    )}
                    onChange={(jobId) => {
                      setFieldValue('jobs', values.jobs.concat(jobId));
                      setSelectedJobs(
                        selectedJobs.concat(
                          allJobsOptions.find(({ value }) => value === jobId),
                        ),
                      );
                    }}
                  />
                </Col>
              </Collapse.Panel>
              <Collapse.Panel
                key="statuses"
                header="Status"
                extra={CANDIDATE_STATUS_OPTIONS.getLabel(candidate?.status)}>
                <StatusList
                  currentStatus={candidate?.status}
                  value={values.status}
                  handleClick={(status) => setFieldValue('status', status)}
                  menuGroups={menuGroups}
                />
                {values.status === CANDIDATE_STATUS.DISMISSED && (
                  <>
                    <Form.Item name="reason" className="mb-10">
                      <ReasonSelect
                        showArrow
                        suffixIcon={<ArrowBottom width={16} height={16} />}
                        name="reason"
                        clearIcon={<Cross width={16} height={16} />}
                        removeIcon={<Cross width={12} height={12} />}
                        placeholder="Select a reason"
                        options={reasonOptions}
                        getPopupContainer={(el) => el.parentElement}
                      />
                    </Form.Item>
                    <Form.Item name="notes" className="mb-10">
                      <Input.TextArea
                        name="notes"
                        placeholder="Feedback or other notes"
                      />
                    </Form.Item>
                  </>
                )}
                {!isChecklistCompleted &&
                  (values.status === CANDIDATE_STATUS.READY ||
                    CANDIDATE_STATUS_OPTIONS.find(
                      (o) => o.value === values.status,
                    )?.group === CANDIDATE_STATUS_GROUPS.CLIENT_DECISION) && (
                    <Typography.Paragraph type="warning" className="mt-10">
                      Checklist is not completed yet. Are you sure?
                    </Typography.Paragraph>
                  )}
              </Collapse.Panel>
            </Collapse>
            <ButtonGroup>
              <Button
                type="ghost"
                size="small"
                className="ml-auto"
                onClick={(e) => {
                  e.stopPropagation();
                  hidePopover();
                }}>
                Cancel
              </Button>
              <Button
                type="primary"
                size="small"
                htmlType="submit"
                onClick={(e) => {
                  e.stopPropagation();
                }}>
                Apply
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </div>
  );
};
