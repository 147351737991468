import { useEffect, useState } from 'react';

export const useUpdates = (ref) => {
  const [updatesState, setUpdatesState] = useState([]);

  useEffect(() => {
    if (!ref) {
      return;
    }
    return ref
      .collection('updates')
      .orderBy('date', 'desc')
      .onSnapshot(async (snaps) => {
        const updates = await Promise.all(
          snaps.docs.map(async (snap) => {
            const data = { id: snap.id, __ref: snap.ref, ...snap.data() };
            const userSnap = await data.createdBy.get();
            data.user = userSnap.data();
            if (data.client) {
              const snap = await data.client.get();
              if (snap) {
                data.client = { id: snap.id, ...snap.data() };
              }
            }
            return data;
          }),
        );
        setUpdatesState(updates);
      });
  }, [setUpdatesState, ref]);

  return updatesState;
};
