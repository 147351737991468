import { Radio } from 'formik-antd';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import { CANDIDATE_TYPE_OPTIONS } from '@yougig/shared/candidates/constants';
import { COLORS } from '@yougig/shared/constants';

import { Proven, Vetted } from '../../icons';

const icons = {
  diamond: Proven,
  vetted: Vetted,
};

export const CandidateTypeRadio = ({ type = 'field', ...props }) => {
  const [{ value: fieldValue }] = useField(props.name);
  return (
    <Radio.Group optionType="button" buttonStyle="solid" {...props}>
      {type === 'field' && <Radio.Button value="">Unknown</Radio.Button>}
      {type === 'filter' && <Radio.Button value="">All</Radio.Button>}
      {CANDIDATE_TYPE_OPTIONS.map(({ value, label, icon, ...options }) => {
        const Icon = icon in icons && icons[icon];
        return (
          <Radio.Button value={value} key={value} {...options}>
            {!!Icon && (
              <Icon color={fieldValue === value ? COLORS.WHITE : COLORS.TEXT} />
            )}
            {label}
          </Radio.Button>
        );
      })}
    </Radio.Group>
  );
};

CandidateTypeRadio.propTypes = {
  type: PropTypes.oneOf(['field', 'filter']),
  name: PropTypes.string.isRequired,
};
