import { useMemo } from 'react';
import { Formik } from 'formik';
import { Typography } from 'antd';

import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { Candidate } from '@yougig/shared/candidates/Candidate';
import { Profile } from '@yougig/shared/talents/Profile';
import {
  AVAILABILITY,
  CANDIDATE_STATUS,
} from '@yougig/shared/candidates/constants';
import { useTalentDrawerContext } from '@yougig/ui/components/talents/TalentDrawerContext';

import { CandidateForm, schema, transformFormPayload } from './CandidateForm';
import { db } from '../../utils/firebase';
import { useJob } from '../../pages/jobs/JobContext';

const emptyValues = {
  firstName: '',
  title: '',
  internalLinks: [],
  languages: [],
  availability: {
    status: AVAILABILITY.NOT_AVAILABLE,
  },
};

export const CreateCandidate = () => {
  const { userRef } = useAuth();

  const { job, updateCandidateInList } = useJob();

  /**
   * @type {import('@yougig/ui/components/talents/TalentDrawerContext').TalentDrawerContextValue}
   */
  const { candidate, entryKey, profile, setDrawerView, closeDrawer } =
    useTalentDrawerContext();

  const initialValues = useMemo(() => {
    if (profile) {
      return {
        ...emptyValues,
        ...profile?.firebaseData,
      };
    }
    return emptyValues;
  }, [profile]);

  return (
    <div className="drawer-section">
      <Typography.Title level={3}>Create a Candidate</Typography.Title>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={schema}
        onSubmit={async (values, { setFieldError, setSubmitting }) => {
          if (values.email) {
            const existingProfile = await db
              .collection('profiles')
              .where('email', '==', values.email)
              .get();
            if (!existingProfile.empty) {
              setFieldError('email', 'This email is already taken.');
              return;
            }
          }

          const payload = transformFormPayload(values);

          const profile = await Profile.create(payload);
          const nextContextData = { profile };

          if (job?.id) {
            let newCandidate =
              candidate instanceof Candidate
                ? candidate
                : new Candidate({
                    approvedBy: userRef,
                    consultant: profile.ref,
                    name: profile.firstName,
                  });
            await newCandidate.setStatusAndSave(CANDIDATE_STATUS.INCOMING, {
              position: job,
              userRef,
            });

            nextContextData.candidate = newCandidate;
          }
          setSubmitting(false);
          setDrawerView('profile', nextContextData);
          updateCandidateInList(nextContextData, entryKey);
        }}>
        <CandidateForm
          candidate={candidate}
          onCancel={closeDrawer}
          submitLabel={job?.id ? 'Save & Add' : 'Save'}
        />
      </Formik>
    </div>
  );
};
