import styled from 'styled-components';

import { ReportsService } from '@yougig/shared/reports/ReportsService';
import { Lock, Spinner } from '@yougig/ui/icons';
import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { Report } from '@yougig/ui/components/reports/Reports';

const AnimatedSpinner = styled(Spinner)`
  animation: spin 1s infinite;
  width: 24px;
  height: 24px;
  margin: 40px auto;
  color: var(--c_hint);
`;

export const TalentReports = () => {
  /**
   * @typedef {object} DrawerContext
   * @property {import('@yougig/shared/reports/Report').Report} report
   * @property {(viewMode, data) => void} setDrawerView
   */
  /**
   * @type {DrawerContext}
   */
  const { report, setDrawerView } = useDrawerContext();
  const [reports, loading] = ReportsService.useChildReportsForManager(report);

  return (
    <div className="drawer-section drawer-section-lock">
      <Lock className="drawer-section__icon" />
      <div className="row">{loading && <AnimatedSpinner />}</div>
      <Report.List>
        {reports.map(({ profile, report: talentReport }) => (
          <Report.ConsultantItem
            key={profile.id}
            profile={profile}
            report={talentReport}
            disabled={!talentReport}
            onClick={async (e) => {
              e.stopPropagation();
              if (!talentReport) {
                await report.generateConsultantReport(profile.ref);
              } else {
                setDrawerView('talent:report', { talentReport, profile });
              }
            }}
            as={talentReport ? 'button' : 'span'}
          />
        ))}
      </Report.List>
    </div>
  );
};
