import { useQuery } from 'react-query';

import { useApi } from '../api/Api';
import { Candidate } from '../candidates/Candidate';
import { Profile } from '../talents/Profile';

export const useSuggestions = (position) => {
  const { apiClient } = useApi();

  return useQuery(
    ['/candidates', position?.skills],
    async ({ queryKey: [endpoint, candidates_skills] }) => {
      if (!candidates_skills?.length) {
        return [];
      }

      const result = (
        await apiClient.get(endpoint, {
          params: {
            skills: candidates_skills?.join(),
            limit: 20,
          },
        })
      ).data.result;

      return result.map((info) => {
        const candidate = Candidate.fromApi(info);
        const profile = Profile.fromApi(info);
        return {
          candidate,
          profile,
          key: profile.key,
        };
      });
    },
  );
};
