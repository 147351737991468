import { useMemo } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';

import {
  CANDIDATE_STATUS_GROUPS,
  CANDIDATE_STATUS_OPTIONS,
} from '@yougig/shared/candidates/constants';
import { Arrow } from '@yougig/ui/icons';
import services from '@yougig/shared/utils/services';
import { Job } from '@yougig/shared/jobs/Job';

import { StatusPopover } from '../common/StatusPopover';

const MENU_GROUPS = [
  { value: CANDIDATE_STATUS_GROUPS.SEARCH, label: 'Search' },
  { value: CANDIDATE_STATUS_GROUPS.PROCESSING, label: 'Processing' },
  { value: CANDIDATE_STATUS_GROUPS.CLIENT_DECISION, label: 'Client' },
];

const StatusButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  svg {
    margin-left: 6px;
    path {
      fill: #fff;
    }
  }
  &.ant-btn-expanded {
    svg {
      transform: rotate(180deg);
    }
  }
`;

const useClientJobs = (client) => {
  const query = useMemo(
    () =>
      client &&
      services
        .get('db')
        .collection('jobs')
        .where('client', '==', client)
        .withConverter(Job.converter),
    [client],
  );

  return useCollectionDataOnce(query);
};

export const JobStatusButton = ({
  children,
  candidate,
  isChecklistCompleted,
  assignedJobs,
  profile,
  client,
  onRemove,
}) => {
  const [allJobs] = useClientJobs(client?.ref);

  if (!candidate.status) {
    return null;
  }

  return (
    <StatusPopover
      profile={profile}
      allJobs={allJobs}
      assignedJobs={assignedJobs}
      isChecklistCompleted={isChecklistCompleted}
      menuGroups={MENU_GROUPS}
      candidate={candidate}
      onRemove={onRemove}
      title={`${profile?.firstName} ${profile?.lastName} @${client?.businessName}`}>
      {children || (
        <StatusButton type="primary">
          {CANDIDATE_STATUS_OPTIONS.getLabel(candidate.status)}
          <Arrow />
        </StatusButton>
      )}
    </StatusPopover>
  );
};
