import { Button } from 'antd';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import { ReportForm } from '@yougig/ui/components/reports/ReportForm';
import { useTalentDrawerContext } from '@yougig/ui/components/talents/TalentDrawerContext';

import { ReportTimesheetSection } from '../clients/ReportTimesheetSection';

export const TalentReport = () => {
  /**
   * @typedef {object} DrawerContext
   * @prop {import('@yougig/shared/reports/Report').Report} report
   * @prop {import('@yougig/shared/talents/Profile').Profile} profile
   */
  /**
   * @type {DrawerContext}
   */
  const { report, profile, setDrawerView } = useTalentDrawerContext();

  const [client] = useDocumentData(report.client);

  return (
    <>
      <div className="drawer-section">
        <ReportForm
          report={report}
          title="Consultant report"
          subHeading={`${profile.fullName} for ${
            client?.businessName || '...'
          }`}
          statusType="dropdown"
          onClose={() => setDrawerView('profile', { report: null })}
          renderButtons={(formik) => (
            <>
              <Button
                type="ghost"
                htmlType="button"
                onClick={() => setDrawerView('chat')}>
                Question
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={formik.isSubmitting}>
                Save
              </Button>
            </>
          )}
        />
      </div>
      <ReportTimesheetSection report={report} disableEditing />
    </>
  );
};
