export const Save = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M13.833 15.5H2.167A1.667 1.667 0 01.5 13.833V2.167A1.667 1.667 0 012.167.5h9.166L15.5 4.667v9.166a1.666 1.666 0 01-1.667 1.667z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.167 15.5V8.835H3.833v6.667M3.833.5v4.167H10.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
