import { useCallback } from 'react';
import { Button } from 'antd';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { Close } from '@yougig/ui/icons';

import { useDrawerContext } from '../common/DrawerProvider';

const SearchAppliedFilterStyled = styled.div`
  display: flex;
  flex: 1 1;
  min-width: 1px;
  max-width: 100%;
  align-items: center;
  gap: 4px;
  margin-left: auto;
  .ant-btn svg:last-child {
    margin-right: 0;
    margin-left: 8px;
  }
`;

const Filters = styled.div`
  flex: 1 1;
  min-width: 1px;
  max-width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: auto;
  overflow: hidden;
  position: relative;
  &:after {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    background-image: linear-gradient(
      to left,
      var(--c_ghost_white),
      transparent
    );
  }
`;

export const SearchAppliedFilter = () => {
  const { setDrawerView } = useDrawerContext();
  const { initialValues, values, setFieldValue, resetForm, handleSubmit } =
    useFormikContext();

  const resetFieldValue = useCallback(
    (valueType, newValue) => {
      setFieldValue(valueType, newValue || initialValues[valueType]);
      handleSubmit();
    },
    [initialValues, setFieldValue, handleSubmit],
  );

  return (
    <SearchAppliedFilterStyled>
      <Filters>
        {!!values.keywords.length &&
          values.keywords.split(', ').map((val) => (
            <Button
              key={val}
              className="color-desc"
              type="link"
              size="small"
              onClick={() =>
                resetFieldValue(
                  'keywords',
                  values.keywords
                    .split(', ')
                    .filter((item) => item !== val)
                    .join(', '),
                )
              }>
              {val}
              <Close />
            </Button>
          ))}
        {!!values.exact_keywords.length &&
          values.exact_keywords?.split(', ').map(
            (val) =>
              val && (
                <Button
                  key={val}
                  className="color-desc"
                  type="link"
                  size="small"
                  onClick={() =>
                    resetFieldValue(
                      'exact_keywords',
                      values.exact_keywords
                        .split(', ')
                        .filter((item) => item !== val)
                        .join(', '),
                    )
                  }>
                  {val}
                  <Close />
                </Button>
              ),
          )}
      </Filters>
      {Object.values(values).filter(Boolean).length > 0 && (
        <Button
          type="link"
          size="small"
          onClick={() => setDrawerView('filter')}>
          Applied filters ({Object.values(values).filter(Boolean).length})
        </Button>
      )}
      <Button
        type="link"
        size="small"
        onClick={() => {
          resetForm();
          setDrawerView('filter');
        }}>
        Discard All
      </Button>
    </SearchAppliedFilterStyled>
  );
};
