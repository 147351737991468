import { Button, Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { CANDIDATE_STATUS_OPTIONS } from '@yougig/shared/candidates/constants';

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  min-width: 100px;
`;

const StatusItem = styled.li`
  position: relative;

  &:before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 2px;
    position: absolute;
    left: -10px;
    top: calc(50% - 3px);
    ${(props) =>
      props.selected && !props.disabled && 'background: var(--c_primary);'};
    ${(props) => !props.selected && 'display: none;'};
  }

  .ant-btn {
    padding: 0;
    font-size: 14px;
    color: var(
      ${(props) =>
        props.disabled
          ? '--c_hint'
          : props.selected
          ? '--c_primary'
          : '--c_text'}
    );
  }
`;

const GroupHeader = styled.h6`
  font-size: 12px;
  color: var(--c_description);
  margin-bottom: 12px;
`;

export const StatusList = ({
  currentStatus,
  handleClick,
  value,
  menuGroups,
}) => {
  return (
    <Row>
      {menuGroups.map(({ value: group, label: groupLabel }) => (
        <Col span={8} key={group}>
          {groupLabel && <GroupHeader>{groupLabel}</GroupHeader>}
          <List>
            {CANDIDATE_STATUS_OPTIONS.filter((s) => s.group === group).map(
              (option) => (
                <StatusItem
                  key={option.value}
                  disabled={option.value === currentStatus}
                  selected={option.value === value}>
                  <Button
                    type="link"
                    onClick={() => handleClick(option.value)}
                    disabled={option.value === currentStatus}>
                    {option.label}
                  </Button>
                </StatusItem>
              ),
            )}
          </List>
        </Col>
      ))}
    </Row>
  );
};
