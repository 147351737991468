import { Options } from '../utils/Options';

export const UPDATES_TYPE = {
  JOB: 'job',
  TALENT: 'talent',
};

export const UPDATES_AUTHOR = {
  CONSULTANT: 'consultant',
  CLIENT: 'client',
  MANAGER: 'manager',
};

export const UPDATES_AUTHOR_OPTIONS = new Options(
  {
    label: 'Consultant',
    value: UPDATES_AUTHOR.CONSULTANT,
    applicable: [UPDATES_TYPE.TALENT],
  },
  {
    label: 'Client',
    value: UPDATES_AUTHOR.CLIENT,
    applicable: [UPDATES_TYPE.TALENT, UPDATES_TYPE.JOB],
  },
  {
    label: 'Manager',
    value: UPDATES_AUTHOR.MANAGER,
    applicable: [UPDATES_TYPE.TALENT, UPDATES_TYPE.JOB],
  },
);
