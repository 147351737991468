import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { useChatInstance } from '@yougig/shared/chat/useChat';
import { useChatMessages } from '@yougig/shared/chat/useChatMessages';
import { CHAT_TYPES, SENDER_ROLES } from '@yougig/shared/chat/constants';
import { Chat } from '@yougig/ui/components/chat/Chat';

export const ClientChat = () => {
  const { client } = useDrawerContext();
  const chat = useChatInstance(client.__ref, CHAT_TYPES.CLIENT);
  const chatProps = useChatMessages(chat, SENDER_ROLES.OWNER);

  return <Chat title={client.businessName} {...chatProps} />;
};
