import classNames from 'classnames';

import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { Drawer } from '@yougig/ui/components/elements/Drawer';

import { ClientChat } from './ClientChat';
import { ClientDetails } from './ClientDetails';
import { CandidateChat } from '../resources/CandidateChat';
import { TalentReport } from './TalentReport';
import { ClientReport } from './ClientReport';

export const ClientDrawer = () => {
  const { viewMode, profile, setDrawerView, closeDrawer } = useDrawerContext();
  const secondDrawerVisible = !!viewMode?.startsWith('talent:');

  return (
    <>
      <Drawer
        className={classNames('drawer-no-padding', {
          'drawer-offset': secondDrawerVisible,
        })}
        visible={!!viewMode}
        onClose={closeDrawer}>
        {viewMode === 'chat' && <ClientChat />}
        {viewMode === 'details' && <ClientDetails />}
        {(viewMode === 'report' || secondDrawerVisible) && <ClientReport />}
      </Drawer>
      {(viewMode === 'report' || secondDrawerVisible) && (
        <Drawer
          className="drawer-no-padding"
          closeOnOutsideClick={false}
          visible={secondDrawerVisible}
          onClose={() => {
            secondDrawerVisible &&
              setDrawerView('report', { talentReport: null, profile: null });
          }}>
          {viewMode === 'talent:chat' && (
            <CandidateChat
              profileRef={profile.ref}
              fullName={profile.fullName}
            />
          )}
          {viewMode === 'talent:report' && <TalentReport />}
        </Drawer>
      )}
    </>
  );
};
