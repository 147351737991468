import { useCallback } from 'react';

import services from '@yougig/shared/utils/services';

export const impersonate = async (uid, host) => {
  try {
    const { data } = await services
      .get('functions')
      .httpsCallable('getAuthToken')({
      uid,
    });
    if (data?.token) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = `${host}?token=${data.token}`;
      link.click();
    }
  } catch (err) {
    console.error(err);
  }
};

export const useImpersonate = (uid, host) =>
  useCallback(
    async ({ domEvent }) => {
      domEvent && domEvent.stopPropagation();
      await impersonate(uid, host);
    },
    [uid, host],
  );
