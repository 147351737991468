import { Button, Space, Typography } from 'antd';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import {
  REPORT_STATUS,
  REPORT_STATUS_OPTIONS,
} from '@yougig/shared/reports/constants';
import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { ReportForm } from '@yougig/ui/components/reports/ReportForm';

import { TalentReports } from './TalentReports';

export const ClientReport = () => {
  /**
   * @typedef {object} DrawerContext
   * @prop {import('@yougig/shared/reports/Report').Report} report
   */
  /**
   * @type {DrawerContext}
   */
  const { report: clientReport, client, setDrawerView } = useDrawerContext();
  const [report = clientReport] = useDocumentData(clientReport.ref);
  return (
    <>
      <div className="drawer-section">
        <ReportForm
          report={report}
          title={
            <Space size="middle">
              <span>Client report</span>
              {!!report.totalBillableTime && (
                <Typography.Text type="secondary">
                  {report.totalBillableTime}h
                </Typography.Text>
              )}
            </Space>
          }
          subHeading={client.businessName}
          statusType="dropdown"
          onClose={() => setDrawerView('details', { report: null })}
          renderButtons={(formik) => (
            <>
              <Button
                type="ghost"
                htmlType="submit"
                disabled={formik.isSubmitting}>
                Save
              </Button>
              {REPORT_STATUS_OPTIONS.get(report.status).active && (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={formik.isSubmitting}
                  onClick={() =>
                    formik.setFieldValue('status', REPORT_STATUS.PENDING)
                  }>
                  Send report
                </Button>
              )}
            </>
          )}
        />
      </div>
      <TalentReports />
    </>
  );
};
