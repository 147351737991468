import { Formik } from 'formik';
import styled from 'styled-components';
import classNames from 'classnames';

import { useDrawerContext } from '../common/DrawerProvider';
import { Drawer } from '../elements/Drawer';
import { MainContent } from '../layout/MainContent';
import { GlobalSearchForm } from './GlobalSearchForm';
import { GlobalSearchResults } from './GlobalSearchResults';

const initialValues = {
  keywords: '',
  exact_keywords: '',
  seniority_gte: 0,
  candidateType: '',
  regions: null,
  country: null,
  scoring_technical_gte: 0,
  scoring_business_gte: 0,
  scoring_creative_gte: 0,
  source: null,
  only_available: false,
};

const StyledDrawer = styled(Drawer)`
  &.ant-drawer-open {
    max-height: 600px;
    height: 50vh !important;
    transform: translateY(1px) !important;
  }
  z-index: 999;
  .ant-form {
    height: 50vh;
    display: flex;
    flex-direction: column;
  }
  .ant-layout-content {
    margin: 0 auto;
  }
  &.ant-drawer-open {
    transition: all 0.2s;
  }
  &.collapsed {
    max-height: 106px;
    .ant-drawer-body {
      overflow: hidden;
    }
  }
  .ant-drawer-body {
    transition: all 0.2s;
    padding: 0;
  }
  .page-heading {
    padding: 28px 10px;
    margin: 0 -10px 12px;
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: #fff;
    .ant-typography {
      margin-bottom: 0;
    }
    @media (max-width: 767px) {
      padding: 16px 10px;
    }
  }
  .form-actions {
    margin: 12px -10px 0;
    padding: 28px 10px;
    position: sticky;
    bottom: 0;
    min-width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
    z-index: 5;
    .ant-btn {
      width: 140px;
      & + .ant-btn {
        margin-left: 10px;
      }
    }
    @media (max-width: 767px) {
      padding: 16px 10px;
    }
  }
  .ant-collapse {
    margin-left: -5px;
    margin-right: -5px;
    &:not(:last-child) {
      margin-bottom: 32px;
    }
    > .ant-collapse-item {
      border-bottom: 0;
      margin-bottom: 32px;

      &:first-child {
        border-top: 0;
      }

      .ant-collapse-content {
        border: 0;
        padding-left: 5px;
        padding-right: 5px;
      }
      .ant-collapse-content-box {
        padding: 24px 0 8px;
      }

      > .ant-collapse-header {
        padding: 0 5px;
        margin-bottom: 0;
        background-color: transparent;

        &:hover {
          background-color: transparent;
        }

        > svg:not(.ant-collapse-arrow) {
          margin-right: 16px;
        }

        .ant-collapse-arrow {
          margin-top: 0;
        }
      }

      &.ant-collapse-item-active {
        border-top-color: transparent;
        > .ant-collapse-header {
          background-color: transparent;
        }

        .ant-collapse-content {
          border-top-color: transparent;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
`;

export const GlobalSearchDrawer = ({ candidates, columns }) => {
  const { resultCollapsed, viewMode, setDrawerView } = useDrawerContext();
  return (
    <>
      <StyledDrawer
        className={classNames('drawer-horizontal', {
          collapsed: viewMode === 'results' && resultCollapsed,
        })}
        placement="bottom"
        height={600}
        mask={false}
        visible={viewMode}
        closable={viewMode === 'filter'}
        closeOnOutsideClick={false}
        onClose={() => setDrawerView(null)}>
        <MainContent>
          <Formik
            initialValues={initialValues}
            onSubmit={() =>
              setDrawerView('results', { resultCollapsed: false })
            }>
            <>
              {viewMode === 'filter' && <GlobalSearchForm />}
              {viewMode === 'results' && (
                <GlobalSearchResults
                  candidates={candidates}
                  columns={columns}
                />
              )}
            </>
          </Formik>
        </MainContent>
      </StyledDrawer>
    </>
  );
};
