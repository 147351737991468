import { useState } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { Typography, Button, Row, Col, Progress } from 'antd';
import { Form, Slider } from 'formik-antd';
import range from 'lodash/range';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import { COLORS } from '@yougig/shared/constants';

const BtnRow = styled.div`
  display: flex;
  margin-top: 24px;
`;

const Sliders = styled.div`
  max-width: 50%;
`;

const CurrentLabel = styled(Typography.Text).attrs({ color: 'secondary' })`
  font-size: 9px;
`;

const MAX_SCORING = 5;

const SLIDER_POINTS = range(0, MAX_SCORING + 1);

export const ScoringField = ({ name, className, scoring }) => {
  const [{ value }, , { setValue }] = useField(name);
  const [override, setOverride] = useState(!isEmpty(value));

  return (
    <div className={classNames('mb-32', className)}>
      <Row justify="space-between" className="mb-20">
        <Col>
          <Typography.Title level={5}>Scoring</Typography.Title>
        </Col>
        <Col>
          {!override && (
            <Typography.Text type="secondary">
              Scoring will be calculated automatically after creation
            </Typography.Text>
          )}
        </Col>
      </Row>
      {!override && (
        <BtnRow>
          <Button
            type="primary"
            size="small"
            className="ml-auto"
            onClick={() => setOverride(true)}>
            Override
          </Button>
        </BtnRow>
      )}
      {override && (
        <>
          <Sliders>
            <Form.Item
              name={`${name}.creative`}
              label="Creative Skills"
              extra={
                !!scoring && (
                  <>
                    <CurrentLabel>Current</CurrentLabel>
                    <Progress
                      strokeColor={COLORS.DESCRIPTION}
                      percent={scoring.creative * 20 || 1}
                      showInfo={false}
                    />
                  </>
                )
              }>
              <Slider
                name={`${name}.creative`}
                marks={SLIDER_POINTS}
                max={MAX_SCORING}
                tipFormatter={null}
              />
            </Form.Item>
            <Form.Item
              name={`${name}.technical`}
              label="Technical Skills"
              extra={
                !!scoring && (
                  <>
                    <CurrentLabel>Current</CurrentLabel>
                    <Progress
                      strokeColor={COLORS.DESCRIPTION}
                      percent={scoring.technical * 20 || 1}
                      showInfo={false}
                    />
                  </>
                )
              }>
              <Slider
                name={`${name}.technical`}
                marks={SLIDER_POINTS}
                max={MAX_SCORING}
                tipFormatter={null}
              />
            </Form.Item>
            <Form.Item
              name={`${name}.business`}
              label="Business Skills"
              extra={
                !!scoring && (
                  <>
                    <CurrentLabel>Current</CurrentLabel>
                    <Progress
                      strokeColor={COLORS.DESCRIPTION}
                      percent={scoring.business * 20 || 1}
                      showInfo={false}
                    />
                  </>
                )
              }>
              <Slider
                name={`${name}.business`}
                marks={SLIDER_POINTS}
                max={MAX_SCORING}
                tipFormatter={null}
              />
            </Form.Item>
          </Sliders>
          <BtnRow>
            <Button
              type="ghost"
              size="small"
              className="ml-auto"
              onClick={() => {
                setValue(null);
                setOverride(false);
              }}>
              Discard
            </Button>
          </BtnRow>
        </>
      )}
    </div>
  );
};
