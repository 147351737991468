import { Button } from 'antd';

import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { ReportForm } from '@yougig/ui/components/reports/ReportForm';
import { REPORT_STATUS_OPTIONS } from '@yougig/shared/reports/constants';

import { ReportTimesheetSection } from './ReportTimesheetSection';

export const TalentReport = () => {
  /**
   * @typedef {object} DrawerContext
   * @prop {import('@yougig/shared/reports/Report').Report} talentReport
   * @prop {import('@yougig/shared/reports/Report').Report} report
   */
  /**
   * @type {DrawerContext}
   */
  const { talentReport, report, client, profile, setDrawerView } =
    useDrawerContext();

  return (
    <>
      <div className="drawer-section">
        <ReportForm
          report={talentReport}
          title="Consultant report"
          subHeading={`${profile.fullName} for ${client.businessName}`}
          statusType="dropdown"
          onClose={() =>
            setDrawerView('report', { talentReport: null, profile: null })
          }
          renderButtons={(formik) => (
            <>
              <Button
                type="ghost"
                htmlType="button"
                onClick={() => setDrawerView('talent:chat')}>
                Question
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={formik.isSubmitting}>
                Save
              </Button>
            </>
          )}
        />
      </div>
      <ReportTimesheetSection
        report={talentReport}
        disableEditing={!REPORT_STATUS_OPTIONS.get(report.status).active}
      />
    </>
  );
};
