import formatDate from 'date-fns/format';
import { Typography } from 'antd';

export const lastMessageColumn = {
  title: 'Chat',
  dataIndex: 'lastMessage',
  ellipsis: true,
  render: (lastMessage) => {
    if (!lastMessage) {
      return null;
    }
    return (
      <>
        {formatDate(lastMessage.dateTime, 'dd.MM.yyyy')}
        <br />
        <Typography.Paragraph ellipsis={{ rows: 1 }}>
          <span className="info">{lastMessage.authorName}: </span>
          {lastMessage.message ||
            (lastMessage.attachments?.length ? 'Attachment' : '')}
        </Typography.Paragraph>
      </>
    );
  },
};
