import { useState } from 'react';
import { Button, Popover, Popconfirm, Timeline, Typography } from 'antd';
import formatDate from 'date-fns/format';

import { Add, Cross } from '@yougig/ui/icons';
import { ShowMoreText } from '@yougig/ui/components/elements/ShowMoreText';
import { useUpdates } from '@yougig/shared/updates/useUpdates';
import {
  UPDATES_AUTHOR,
  UPDATES_AUTHOR_OPTIONS,
  UPDATES_TYPE,
} from '@yougig/shared/updates/constants';
import { COLORS } from '@yougig/shared/constants';
import { Update } from '@yougig/shared/updates/Update';
import { useAuth } from '@yougig/shared/auth/AuthProvider';

import { UpdateForm } from './UpdateForm';

export const Updates = ({ parentRef, type = UPDATES_TYPE.TALENT, client }) => {
  const { userRef } = useAuth();
  const [isVisible, setIsVisible] = useState(false);
  const [isShowPrevious, setIsShowPrevious] = useState(false);
  const updates = useUpdates(parentRef);

  const handleVisibleChange = (visible) => {
    setIsVisible(visible);
  };

  const closeForm = () => {
    setIsVisible(false);
  };

  return (
    <div className="updates">
      <div className="updates__info">
        This information is strictly internal and only visible to managers
      </div>

      <div className="updates__timeline">
        <Popover
          content={
            <UpdateForm
              parentRef={parentRef}
              type={type}
              closeForm={closeForm}
              client={client}
            />
          }
          placement="bottomRight"
          trigger="click"
          visible={isVisible}
          onVisibleChange={handleVisibleChange}
          destroyTooltipOnHide={{ keepParent: true }}
          overlayClassName="updates-popover with-arrow"
          getPopupContainer={(trigger) => trigger.parentElement}>
          <span className="updates__timeline__btn">
            <Button
              type="primary"
              size="small"
              htmlType="button"
              icon={<Add />}
            />
            {!updates.length && 'Add Update'}
          </span>
        </Popover>
        <Timeline mode="left">
          {updates
            .slice(0, !isShowPrevious ? 5 : updates.length)
            .map((update) => (
              <Timeline.Item
                key={update.id}
                color={COLORS.PERSIAN_BLUE}
                label={
                  <>
                    <Typography.Text type="secondary">
                      {formatDate(update?.date?.toDate(), 'dd.MM.yyyy')}
                    </Typography.Text>
                    <Typography.Text type="secondary">
                      {`by ${update?.user.firstName}`}
                    </Typography.Text>
                  </>
                }>
                {userRef.isEqual(update.createdBy) && (
                  <Popconfirm
                    title="Are you sure you want to remove?"
                    overlayClassName="confirm-popover confirm-popover-updates with-arrow"
                    placement="bottomLeft"
                    cancelButtonProps={{
                      type: 'ghost',
                    }}
                    okButtonProps={{
                      type: 'ghost',
                      danger: true,
                    }}
                    okText="Delete"
                    icon={false}
                    onConfirm={() => Update.delete(update)}>
                    <Button
                      icon={<Cross color="currentColor" />}
                      className="update-remove-btn"
                    />
                  </Popconfirm>
                )}
                <Typography.Text type="secondary">
                  {update.authorType === UPDATES_AUTHOR.MANAGER
                    ? 'We'
                    : UPDATES_AUTHOR_OPTIONS.getLabel(update.authorType)}
                  {!!update.client &&
                    update.authorType !== UPDATES_AUTHOR.CLIENT &&
                    ` about`}
                  {` ${update.client?.businessName || ''}`}
                </Typography.Text>
                <span className="updates__timeline__message">
                  <ShowMoreText text={update.message} lines={4} />
                </span>
              </Timeline.Item>
            ))}
          {!isShowPrevious && updates.length > 5 && (
            <Timeline.Item
              key="showMore"
              className="updates__timeline__show-more"
              color={COLORS.PERSIAN_BLUE}
              label={
                <Button type="link" onClick={() => setIsShowPrevious(true)}>
                  Show Previous Updates
                </Button>
              }
            />
          )}
        </Timeline>
      </div>
    </div>
  );
};
