import { useState } from 'react';

import { Close } from '@yougig/ui/icons';
import { Drawer } from '@yougig/ui/components/elements/Drawer';
import { AssignmentDetailsTopInfo } from '@yougig/ui/components/assignments/AssignmentDetailsTopInfo';
import { DrawerProvider } from '@yougig/ui/components/common/DrawerProvider';
import { AssignmentDetails as AssignmentDetailsStyled } from '@yougig/ui/components/assignments/AssignmentDetails';
import { TalentDrawerProvider } from '@yougig/ui/components/talents/TalentDrawerContext';
import { Job } from '@yougig/shared/jobs/Job';

import { JobDetailsLockInfo } from './components/JobDetailsLockInfo';
import { JobPipelines } from './components/JobPipelines';
import { useJob } from './JobContext';
import { AssignmentForm } from './components/AssignmentForm';

export const JobDetails = () => {
  const { job, jobLoading } = useJob();
  const [editDrawer, setDrawerVisibility] = useState(false);

  if (!job) {
    return null;
  } else if (!jobLoading && !(job instanceof Job)) {
    return <h1>404 Not Found</h1>;
  }

  return (
    <TalentDrawerProvider job={job}>
      <AssignmentDetailsStyled>
        <AssignmentDetailsTopInfo
          data={job}
          visibleStatus={false}
          onEdit={() => setDrawerVisibility(true)}
        />
        <JobDetailsLockInfo job={job} />
        <Drawer
          placement="right"
          visible={editDrawer}
          onClose={() => setDrawerVisibility(false)}
          closeIcon={<Close width={16} height={16} />}
          width={708}>
          <AssignmentForm
            assignment={job}
            onClose={() => setDrawerVisibility(false)}
          />
        </Drawer>
        <DrawerProvider>
          <JobPipelines />
        </DrawerProvider>
      </AssignmentDetailsStyled>
    </TalentDrawerProvider>
  );
};
