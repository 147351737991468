import { useState, useCallback, useEffect } from 'react';
import { Select } from 'antd';
import { useField } from 'formik';

import services from '@yougig/shared/utils/services';
import { useApi } from '@yougig/shared/api/Api';

import { Cross } from '../../icons';

export const ClientAutoComplete = ({ onChange, value, ...props }) => {
  const { apiClient } = useApi();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // load selected client from firebase and generate an option for it
    // to display a value in the field.
    if (!options.length && value) {
      value
        .get()
        .then((snap) =>
          setOptions([{ value: snap.id, label: snap.get('businessName') }]),
        );
    }
  }, [value, options]);

  const onSearch = useCallback(
    async (keywords) => {
      if (keywords.length < 3) {
        return;
      }
      try {
        const response = await apiClient.get('clients', {
          params: { keywords },
        });
        setOptions(
          response.data.result.map(({ business_name, external_id }) => ({
            value: external_id,
            label: business_name,
          })),
        );
      } catch (err) {
        console.log('API error occurred', err.data.message);
      }
    },
    [apiClient, setOptions],
  );

  return (
    <Select
      allowClear
      clearIcon={<Cross width={16} height={16} />}
      value={value?.id}
      options={options}
      filterOption={false}
      showSearch
      showArrow={false}
      onSearch={onSearch}
      notFoundContent={'Start typing client name'}
      getPopupContainer={(el) => el.parentElement}
      onChange={(client) => {
        let clientRef = null;
        if (client) {
          clientRef = services.get('db').doc(`clients/${client}`);
        }
        onChange(clientRef);
      }}
      placeholder="Start typing client name"
      className="select-autocomplete"
      {...props}
    />
  );
};

export const ClientAutoCompleteField = ({ name, ...props }) => {
  const [{ value }, , { setValue }] = useField(name);

  return <ClientAutoComplete onChange={setValue} value={value} {...props} />;
};
