import formatDate from 'date-fns/format';

const renderFullName = ({ firstName = '', lastName = '' } = {}) =>
  `${firstName} ${lastName}`;
const renderContactInfo = ({ email, phone } = {}) => (
  <>
    {!!email && <a href={`mailto:${email}`}>{email}</a>}
    {!!email && !!phone && <br />}
    {!!phone && (
      <a href={`tel:${phone.code}${phone.number}`}>
        {phone.code} {phone.number}
      </a>
    )}
  </>
);

export const baseColumns = [
  {
    title: 'Client',
    dataIndex: 'businessName',
    key: 'businessName',
    width: 200,
    render(businessName, record) {
      return (
        <>
          {businessName}
          {!!record.country && (
            <>
              <br />
              <span className="info">{record.country}</span>
            </>
          )}
        </>
      );
    },
  },
  {
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    width: 120,
    render: (date) =>
      date && date.toDate ? formatDate(date.toDate(), 'MMM dd, yyyy') : '',
  },
  {
    title: 'Signatory',
    key: 'signPerson',
    width: 250,
    render: (_, record) => {
      const account = record.signPerson || record.owner;
      if (!account) {
        return null;
      }
      return (
        <>
          {renderFullName(account)}
          <br />
          {renderContactInfo(account)}
        </>
      );
    },
  },
];
