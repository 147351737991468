import { Formik } from 'formik';
import { Button, Typography, Row } from 'antd';
import { Form } from 'formik-antd';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import addHours from 'date-fns/addHours';

import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { useTalentDrawerContext } from '@yougig/ui/components/talents/TalentDrawerContext';
import { Attachments } from '@yougig/shared/common/Attachments';
import {
  REQUEST_AUTHOR,
  REQUEST_STATUS,
  REQUEST_STATUS_OPTIONS,
  REQUEST_TYPES,
} from '@yougig/shared/actions/constants';
import { schema } from '@yougig/shared/interviews/schema';
import { RequestService } from '@yougig/shared/actions/RequestService';
import services from '@yougig/shared/utils/services';

import { RequestFormFields } from '../elements/RequestFormFields';
import { StatusLabel } from '../elements/Status';
import { LabelWrapper } from './RequestForm';
import { StatusDropdown } from '../elements/StatusDropdown';

const TOMORROW = addHours(startOfTomorrow(new Date()), 7);

export const BookInterview = () => {
  /**
   * @type {import('@yougig/ui/components/talents/TalentDrawerContext').TalentDrawerContextValue}
   */
  const {
    profile,
    request: interview,
    job,
    setDrawerView,
  } = useTalentDrawerContext();
  const { userRef } = useAuth();

  return (
    <div className="drawer-section">
      <Formik
        initialValues={{
          dateTime: interview?.dateTime || TOMORROW,
          notes: interview?.notes || '',
          authorType: interview?.authorType || REQUEST_AUTHOR.CLIENT,
          attachments:
            interview?.attachments?.map(Attachments.toRcUpload) || [],
          client: interview?.client || job?.client || null,
          status: interview?.status || REQUEST_STATUS.SUBMITTED,
        }}
        validationSchema={schema}
        onSubmit={async (values, { setSubmitting }) => {
          const attachments = values.attachments.map(Attachments.fromRcUpload);
          if (profile.isDraft) {
            await profile.save();
          }
          await (
            interview ||
            RequestService.createRequest(
              profile,
              userRef,
              REQUEST_TYPES.INTERVIEW,
            )
          ).save({
            ...values,
            attachments,
          });
          setSubmitting(false);
          setDrawerView('profile', { profile });
        }}>
        {({ values, isSubmitting, dirty, setFieldValue }) => (
          <>
            <Form layout="vertical">
              <Row align="top">
                <Typography.Title level={3}>
                  {interview ? 'Edit' : 'Book'} Interview
                </Typography.Title>
                {services.get('config').PROJECT === 'manager' ? (
                  <StatusDropdown
                    name="status"
                    options={REQUEST_STATUS_OPTIONS}
                    variant="manager"
                    className="ml-auto"
                  />
                ) : (
                  <LabelWrapper>
                    <StatusLabel
                      {...REQUEST_STATUS_OPTIONS.get(values.status)}
                    />
                  </LabelWrapper>
                )}
              </Row>
              <RequestFormFields requestType={REQUEST_TYPES.INTERVIEW} />
              <Row justify="end">
                {services.get('config').PROJECT === 'manager' ? (
                  <Button type="ghost" htmlType="submit" loading={isSubmitting}>
                    Save
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isSubmitting}
                    onClick={() => {
                      values.status === REQUEST_STATUS.RETURNED &&
                        setFieldValue(
                          'status',
                          dirty
                            ? REQUEST_STATUS.SUBMITTED
                            : REQUEST_STATUS.CONFIRMED,
                        );
                    }}>
                    {values.status === REQUEST_STATUS.RETURNED
                      ? dirty
                        ? 'Submit'
                        : 'Confirm'
                      : 'Save'}
                  </Button>
                )}
              </Row>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};
