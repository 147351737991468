import { Typography } from 'antd';
import styled from 'styled-components';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import InfiniteScroll from 'react-infinite-scroller';
import { useFormikContext } from 'formik';

import { useApi } from '@yougig/shared/api/Api';
import { Profile } from '@yougig/shared/talents/Profile';
import { Candidate } from '@yougig/shared/candidates/Candidate';
import { CANDIDATE_TYPE } from '@yougig/shared/candidates/constants';

import { SearchAppliedFilter } from './SearchAppliedFilter';
import { useDrawerContext } from '../common/DrawerProvider';
import { Arrow } from '../../icons';
import { TalentTableWithDrawer } from '../talents/TalentTableWithDrawer';

const GlobalSearchResultsStyled = styled.div`
  padding: 28px 0;
  transition: all 0.2s;
  .ant-drawer.collapsed & {
    @media (min-width: 768px) {
      padding: 22px 0;
    }
  }
  .ant-table-wrapper {
    transition: all 0.2s;
    .ant-drawer.collapsed & {
      opacity: 0;
    }
  }
  .ant-table table {
    box-shadow: none;
  }
  @media (max-width: 767px) {
    padding: 16px 0;
  }
`;

const SearchHeaderWrapper = styled.div`
  position: sticky;
  transition: all 0.2s;
  top: 28px;
  z-index: 5;
  margin-bottom: -10px;
  &:before {
    position: absolute;
    content: '';
    left: -10px;
    top: -28px;
    right: -10px;
    bottom: 0;
    background-color: #fff;
    z-index: 0;
    pointer-events: none;
  }
  .ant-drawer.collapsed & {
    @media (min-width: 768px) {
      top: 22px;
    }
  }
  @media (max-width: 767px) {
    top: 16px;
  }
`;

const SearchHeader = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  border: 3px solid var(--c_light_grey);
  border-bottom-color: transparent;
  background-color: var(--c_ghost_white);
  border-radius: 10px 10px 0 0;
  transition: all 0.2s;
  .ant-typography {
    margin: 0;
    white-space: nowrap;
    span {
      color: var(--c_hint);
      margin-left: 6px;
    }
  }
  .ant-btn {
    display: flex;
    align-items: center;
    svg {
      margin-top: 0;
      &:last-child {
        margin-right: 0;
        margin-left: 8px;
      }
    }
    &.color-desc:not(:hover) {
      color: var(--c_description);
    }
  }
  .icon-expanded {
    transition: all 0.2s;
    cursor: pointer;
    transform: rotate(180deg);
    &.active {
      transform: rotate(0);
    }
  }
  .ant-drawer.collapsed & {
    border-bottom-color: var(--c_light_grey);
    border-radius: 10px;
  }
`;

export const GlobalSearchResults = ({ candidates, columns }) => {
  const { paginatedQueryFn } = useApi();
  const { values } = useFormikContext();
  const { setDrawerView, resultCollapsed } = useDrawerContext();
  const searchValues = useMemo(
    () =>
      Object.entries(values).reduce((acc, [key, value]) => {
        if (key === 'candidateType') {
          if (value === CANDIDATE_TYPE.PROVEN) {
            acc.only_proven = true;
          } else if (value === CANDIDATE_TYPE.VETTED) {
            acc.only_vetted = true;
          }
        } else if (key === 'regions' && value?.length) {
          acc.regions = value.join(', ');
        } else if (value) {
          acc[key] = value;
        }
        return acc;
      }, {}),
    [values],
  );
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
    ['candidates', searchValues],
    async (params) => {
      const data = await paginatedQueryFn(params);
      let result = [];
      for await (const item of data.result) {
        let profile = Profile.fromApi(item);
        let candidate = Candidate.fromApi(item);
        if (profile.ref) {
          const profileSnap = await profile.ref.get();
          if (profileSnap.exists) {
            profile = profileSnap.data();
            profile.apiData = item;
          }
        }
        result.push({ profile, candidate, key: profile.key });
      }
      return result;
    },
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.next ? allPages.length : undefined,
    },
  );

  const dataSource = useMemo(
    () =>
      data?.pages.flat().map((item) => {
        const entry = candidates.find(
          (a) => a.profile.key === item.profile.key,
        );
        if (entry) {
          entry.profile.apiData = item.profile.apiData;
          return entry;
        }
        return item;
      }) || [],
    [data, candidates],
  );

  return (
    <GlobalSearchResultsStyled>
      <SearchHeaderWrapper>
        <SearchHeader>
          <Arrow
            className={classNames('icon-expanded', {
              active: resultCollapsed,
            })}
            onClick={() =>
              setDrawerView('results', {
                resultCollapsed: !resultCollapsed,
              })
            }
          />
          <Typography.Title level={4}>
            Global search
            <span>{dataSource.length}</span>
          </Typography.Title>
          <SearchAppliedFilter />
        </SearchHeader>
      </SearchHeaderWrapper>
      <InfiniteScroll
        initialLoad={false}
        threshold={600}
        loadMore={() => fetchNextPage()}
        hasMore={hasNextPage}>
        <TalentTableWithDrawer
          dataSource={dataSource}
          showHeader={false}
          loading={isLoading}
          columns={columns}
        />
      </InfiniteScroll>
    </GlobalSearchResultsStyled>
  );
};
