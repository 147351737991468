export const Delete = (props) => {
  return (
    <svg
      width={12}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M1.375 2.91c0-.932.756-1.687 1.688-1.687h5.874c.932 0 1.688.755 1.688 1.687 0 .173-.14.313-.313.313H1.688a.312.312 0 01-.313-.313z"
        stroke="#FF5170"
        strokeWidth={0.75}
      />
      <path d="M5.5.848a.5.5 0 011 0h-1z" fill="#FF5170" />
      <path
        d="M3.047 10.49l-.638-7.267h7.182l-.638 7.267a1.625 1.625 0 01-1.619 1.483H4.666a1.625 1.625 0 01-1.62-1.483z"
        stroke="#FF5170"
        strokeWidth={0.75}
      />
    </svg>
  );
};
