import { Chat } from '@yougig/ui/components/chat/Chat';
import { useChatInstance } from '@yougig/shared/chat/useChat';
import { CHAT_TYPES, SENDER_ROLES } from '@yougig/shared/chat/constants';
import { useChatMessages } from '@yougig/shared/chat/useChatMessages';

export const CandidateChat = ({ profileRef, fullName }) => {
  const chat = useChatInstance(profileRef, CHAT_TYPES.CONSULTANT);
  const chatProps = useChatMessages(chat, SENDER_ROLES.OWNER);

  return <Chat title={fullName} {...chatProps} />;
};
