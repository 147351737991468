import styled from 'styled-components';

export const TalentActionsBar = styled.div`
  position: sticky;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--c_white);
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1),
    inset 0px -1px 0px var(--c_light_grey);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 48px;
  margin-top: auto;
`;

TalentActionsBar.Buttons = styled.div`
  display: flex;
  margin-left: auto;
  gap: 16px;
`;
