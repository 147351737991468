import * as React from 'react';

export const Filter = (props) => (
  <svg width={12} height={12} viewBox="0 0 12 12" fill="none" {...props}>
    <rect y="2.125" width="8" height="1" rx="0.5" fill="currentColor" />
    <rect y="9.125" width="8" height="1" rx="0.5" fill="currentColor" />
    <rect x="4" y="5.625" width="8" height="1" rx="0.5" fill="currentColor" />
    <rect
      x="10.5"
      y="2.125"
      width="1.5"
      height="1"
      rx="0.5"
      fill="currentColor"
    />
    <rect
      x="10.5"
      y="9.125"
      width="1.5"
      height="1"
      rx="0.5"
      fill="currentColor"
    />
    <rect y="5.625" width="1.5" height="1" rx="0.5" fill="currentColor" />
    <circle cx="9.25" cy="2.5" r="1.25" stroke="currentColor" />
    <circle cx="9.25" cy="9.5" r="1.25" stroke="currentColor" />
    <circle cx="2.75" cy="6" r="1.25" stroke="currentColor" />
  </svg>
);
