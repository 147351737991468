import { Button, Popover } from 'antd';
import { useState, useMemo } from 'react';
import { Formik } from 'formik';
import { Checkbox, Form } from 'formik-antd';
import styled from 'styled-components';

import { MANAGER_CHECKLIST } from '@yougig/shared/candidates/constants';

const ButtonGroup = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
`;

const CheckedListSpan = styled.span`
  color: var(--c_primary);
  margin-left: 6px;
  cursor: pointer;
`;

export const CheckList = ({ assignment, candidate }) => {
  const [visiblePopover, setPopoverVisibility] = useState(false);

  const checklist = useMemo(
    () => ({ ...assignment.checklist, applied: !!assignment.applied }),
    [assignment],
  );

  const initialValues = useMemo(() => {
    const defaults = MANAGER_CHECKLIST.reduce(
      (acc, cur) => ({ ...acc, [cur.value]: false }),
      {},
    );
    return { ...defaults, ...checklist };
  }, [checklist]);

  const checkedFields = Object.values(checklist).filter(Boolean).length;

  const popoverContent = (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          const { applied, ...props } = values;
          await candidate.updateAssignment(assignment.id, {
            applied,
            checklist: props,
          });
          setPopoverVisibility(false);
        }}>
        {({ handleSubmit }) => (
          <Form>
            {MANAGER_CHECKLIST.map((o) => (
              <Form.Item name={o.value} className="mb-0">
                <Checkbox name={o.value}>{o.label}</Checkbox>
              </Form.Item>
            ))}
            <ButtonGroup>
              <Button
                type="ghost"
                size="small"
                onClick={() => setPopoverVisibility(false)}>
                Cancel
              </Button>
              <Button
                type="primary"
                size="small"
                htmlType="submit"
                onClick={handleSubmit}>
                Apply
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    </>
  );

  return (
    <Popover
      placement="topRight"
      trigger="click"
      title="Checklist"
      visible={visiblePopover}
      onVisibleChange={setPopoverVisibility}
      overlayClassName="checklist-popover"
      openClassName="ant-btn-expanded"
      content={popoverContent}>
      <CheckedListSpan>checklist {checkedFields}/7</CheckedListSpan>
    </Popover>
  );
};
