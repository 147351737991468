import { Popover } from 'antd';
import { useState } from 'react';

import { Report } from '@yougig/ui/components/reports/Reports';
import { ReportDateForm } from '@yougig/ui/components/reports/ReportDateForm';
import { ReportsService } from '@yougig/shared/reports/ReportsService';
import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';

export const ReportsList = () => {
  const { client, setDrawerView } = useDrawerContext();
  const [visibleCreateForm, setCreateFormVisiblity] = useState(false);
  const [reports] = ReportsService.useClientReports(client.__ref);

  return (
    <Report.List>
      <Popover
        visible={visibleCreateForm}
        content={
          <ReportDateForm onClose={() => setCreateFormVisiblity(false)} />
        }
        title="Choose date range"
        placement="bottom"
        trigger="click"
        onVisibleChange={setCreateFormVisiblity}
        overlayClassName="reports-popover with-arrow"
        getPopupContainer={(trigger) => trigger.parentElement}>
        <Report.Create />
      </Popover>
      {reports.map((report, index) => (
        <Report.Item
          key={index}
          report={report}
          onClick={() => setDrawerView('report', { report })}
        />
      ))}
    </Report.List>
  );
};
