import { Typography, Switch, Button } from 'antd';
import styled from 'styled-components';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useCallback, useState } from 'react';
import formatDistance from 'date-fns/formatDistance';

import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { Report } from '@yougig/ui/components/reports/Reports';

import { functions } from '../../utils/firebase';

const SyncedTalents = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  gap: 4px;

  span.ant-typography {
    white-space: nowrap;
  }
`;

export const ReportsSectionHeading = () => {
  const { client } = useDrawerContext();
  const [loading, setLoading] = useState(false);
  const [integration] = useDocumentData(
    client.__ref.collection('integrations').doc('clockify'),
    { refField: '_ref' },
  );

  const sync = useCallback(async () => {
    setLoading(true);
    try {
      await functions.httpsCallable('clockify-syncClientMembers')({
        client: client.id,
      });
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }, [client.id]);

  const toggleIntegration = useCallback(
    async (value) => {
      if (value) {
        integration && (await integration._ref.update({ enabled: true }));
        await sync();
      } else {
        setLoading(true);
        await integration._ref.update({ enabled: false });
        setLoading(false);
      }
    },
    [integration, sync],
  );

  return (
    <>
      <div className="section--heading">
        <Typography.Title level={4}>Reports</Typography.Title>

        <label className="toggle">
          <span>Timesheets</span>
          <Switch
            name="enableReporting"
            checked={!!integration?.enabled}
            size="small"
            disabled={loading}
            onChange={toggleIntegration}
          />
        </label>
        {!!integration?.enabled && !!integration?.timestamp && (
          <Typography.Text type="secondary">
            Updated{' '}
            {formatDistance(integration.timestamp.toDate(), new Date(), {
              addSuffix: true,
            })}{' '}
            <Button
              type="link"
              size="small"
              onClick={() => sync()}
              icon={<Report.RefreshIcon $loading={loading} />}
            />
          </Typography.Text>
        )}
      </div>
      {!!integration?.talents?.length && (
        <SyncedTalents>
          <Typography.Text type="secondary">Synced Users:</Typography.Text>
          <Typography.Paragraph ellipsis={{ rows: 3 }}>
            {integration.talents.map((t) => t.fullName).join(', ')}
          </Typography.Paragraph>
        </SyncedTalents>
      )}
    </>
  );
};
