import { Switch, Route, Redirect } from 'react-router-dom';

import { useAuth } from '@yougig/shared/auth/AuthProvider';

import { AuthLayout } from '../components/layout/AuthLayout';
import { MainLayout } from '../components/layout/MainLayout';
import { LoginPage } from './auth/Login';
import { Jobs } from './jobs/Jobs';
import { Candidates } from './candidates/Candidates';
import { WaitForApiToken } from '../components/common/WaitForApiToken';
import { Clients } from './clients/Clients';
import { JobDetails } from './jobs/JobDetails';
import { JobProvider } from './jobs/JobContext';

const Routing = () => {
  const { isAuthenticated, initializing } = useAuth();
  if (initializing) {
    return null;
  }

  if (isAuthenticated) {
    return (
      <MainLayout>
        <WaitForApiToken>
          <Switch>
            <Route exact path="/jobs">
              <Jobs />
            </Route>
            <Route path="/jobs/:jobId">
              <JobProvider>
                <JobDetails />
              </JobProvider>
            </Route>
            <Route exact path="/candidates">
              <Candidates />
            </Route>
            <Route exact path="/clients">
              <Clients />
            </Route>
            <Redirect to="/jobs" />
          </Switch>
        </WaitForApiToken>
      </MainLayout>
    );
  }

  return (
    <AuthLayout>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Redirect to="/login" />
      </Switch>
    </AuthLayout>
  );
};

export default Routing;
