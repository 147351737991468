import { ReportsList } from './ReportsList';
import { ReportsSectionHeading } from './ReportsSectionHeading';

export const ReportsSection = () => {
  return (
    <>
      <ReportsSectionHeading />
      <ReportsList />
    </>
  );
};
