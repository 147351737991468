import { useMemo, useState } from 'react';
import { Typography } from 'antd';
import { useInfiniteQuery } from 'react-query';
import InfiniteScroll from 'react-infinite-scroller';
import formatDate from 'date-fns/format';

import { useApi } from '@yougig/shared/api/Api';
import { TalentDrawer } from '@yougig/ui/components/talents/TalentDrawer';
import { TalentDrawerProvider } from '@yougig/ui/components/talents/TalentDrawerContext';
import { baseColumns } from '@yougig/ui/components/talents/baseColumns';
import { Profile } from '@yougig/shared/talents/Profile';
import { InputSearch } from '@yougig/ui/components/elements/InputSearch';
import { TalentTableWithDrawer } from '@yougig/ui/components/talents/TalentTableWithDrawer';

import { TalentDrawerContent } from '../../components/resources/TalentDrawerContent';

export const columnsCandidate = [
  baseColumns.candidate,
  baseColumns.occupation,
  baseColumns.scoring,
  baseColumns.location,
  baseColumns.rate,
  {
    title: 'Actions',
    className: 'cell-actions-stat',
    key: 'manager_weight',
    render: (_, { profile }) => {
      if (!profile.manager_weight) {
        return null;
      }
      const [reports, requests, applies, unanswered, timestamp] =
        profile.manager_weight;
      const values = [
        !!reports && `${reports} pending report${reports > 1 ? 's' : ''}`,
        !!requests && `${requests} open request${requests > 1 ? 's' : ''}`,
        !!applies && `${applies} applied jobs`,
        !!unanswered &&
          `Unanswered ${
            !!timestamp ? formatDate(timestamp, 'dd.MM.yyyy') : 'msg'
          }`,
      ]
        .filter(Boolean)
        .join('\n');
      return <span style={{ whiteSpace: 'pre-wrap' }}>{values}</span>;
    },
  },
];

export const Candidates = () => {
  const [keywords, setKeywords] = useState('');
  const { paginatedQueryFn } = useApi();
  const queryParams = useMemo(
    () => ({
      keywords: keywords,
      sort_by: !!keywords ? 'default' : 'manager_weight',
    }),
    [keywords],
  );
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
    ['/candidates', queryParams],
    paginatedQueryFn,
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.next ? allPages.length : undefined,
    },
  );

  const dataSource = useMemo(
    () =>
      data?.pages.flatMap((page) =>
        page.result.map((item) => {
          const profile = Profile.fromApi(item);
          return { profile, key: profile.key };
        }),
      ) || [],
    [data],
  );

  return (
    <TalentDrawerProvider>
      <div className="page-heading">
        <Typography.Title>Candidates</Typography.Title>
        <InputSearch onSearch={setKeywords} />
      </div>
      <InfiniteScroll
        initialLoad={false}
        threshold={600}
        loadMore={() => fetchNextPage()}
        hasMore={hasNextPage}>
        <TalentTableWithDrawer
          loading={isLoading}
          dataSource={dataSource}
          columns={columnsCandidate}
        />
      </InfiniteScroll>
      <TalentDrawer>
        <TalentDrawerContent />
      </TalentDrawer>
    </TalentDrawerProvider>
  );
};
