import { useMemo, useState } from 'react';
import { Button, Typography } from 'antd';

import {
  CANDIDATE_STATUS,
  CANDIDATE_STATUS_OPTIONS,
  CANDIDATE_STATUS_GROUPS,
} from '@yougig/shared/candidates/constants';
import { useSuggestions } from '@yougig/shared/jobs/useSuggestions';
import { Add, Filter } from '@yougig/ui/icons';
import { TalentDrawer } from '@yougig/ui/components/talents/TalentDrawer';
import { StickyPanel } from '@yougig/ui/components/layout/StickyPanel';
import { useTalentDrawerContext } from '@yougig/ui/components/talents/TalentDrawerContext';
import { InputSearch } from '@yougig/ui/components/elements/InputSearch';
import { ExpandableTableHeader } from '@yougig/ui/components/elements/ExpandableTable';
import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { GlobalSearchDrawer } from '@yougig/ui/components/globalSearch/GlobalSearchDrawer';
import {
  columnsTalent,
  TalentTableWithDrawer,
} from '@yougig/ui/components/talents/TalentTableWithDrawer';

import { useJob } from '../JobContext';
import { TalentDrawerContent } from '../../../components/resources/TalentDrawerContent';

const clientStatuses = CANDIDATE_STATUS_OPTIONS.filter(
  (s) => s.group === CANDIDATE_STATUS_GROUPS.CLIENT_DECISION,
).map((s) => s.value);

const internalStatuses = CANDIDATE_STATUS_OPTIONS.filter(
  (s) => s.group === CANDIDATE_STATUS_GROUPS.PROCESSING,
).map((s) => s.value);

const searchStatuses = CANDIDATE_STATUS_OPTIONS.filter(
  (s) => s.group === CANDIDATE_STATUS_GROUPS.SEARCH,
).map((s) => s.value);

export const JobPipelines = () => {
  const { candidates, job, assignedCandidates, dataLoading } = useJob();
  const { viewMode, setDrawerView: setSearchDrawerView } = useDrawerContext();
  const { setDrawerView } = useTalentDrawerContext();
  const [searchFilter, setSearchFilters] = useState('');

  const assignedCandidatesFiltered = useMemo(
    () =>
      assignedCandidates.filter((item) =>
        item.profile.fullName
          .toLowerCase()
          .includes(searchFilter.toLowerCase()),
      ),
    [assignedCandidates, searchFilter],
  );

  const clientGroups = useMemo(
    () =>
      clientStatuses
        .map((status) => {
          const candidates = assignedCandidatesFiltered.filter(
            (c) => c.candidate.status === status,
          );
          return {
            key: status,
            groupTitle: CANDIDATE_STATUS_OPTIONS.getLabel(status),
            groupLength: candidates?.length,
            children: candidates,
          };
        })
        .filter(({ children }) => children.length),
    [assignedCandidatesFiltered],
  );

  const internalGroups = useMemo(
    () =>
      internalStatuses
        .map((status) => {
          const candidates = assignedCandidatesFiltered.filter(
            (c) => c.candidate.status === status,
          );
          return {
            key: status,
            groupTitle: CANDIDATE_STATUS_OPTIONS.getLabel(status),
            groupLength: candidates?.length,
            children: candidates,
          };
        })
        .filter(({ children }) => children.length),
    [assignedCandidatesFiltered],
  );

  const { data: suggestions = [], loading: isSuggestionsLoading } =
    useSuggestions(job);

  const filteredSuggestions = useMemo(() => {
    const candidates = suggestions.filter((s) =>
      assignedCandidates.every(
        (c) => c.profile.externalId !== s.profile.externalId,
      ),
    );

    return {
      key: 'matches',
      groupTitle: 'Matches',
      groupLength: candidates.length,
      children: candidates,
      loading: isSuggestionsLoading,
    };
  }, [suggestions, assignedCandidates, isSuggestionsLoading]);

  const searchGroups = useMemo(
    () =>
      searchStatuses
        .map((status) => {
          const candidates = assignedCandidatesFiltered.filter(
            (c) => c.candidate.status === status,
          );
          return {
            key: status,
            groupTitle: CANDIDATE_STATUS_OPTIONS.getLabel(status),
            groupLength: candidates?.length,
            children: candidates,
          };
        })
        .concat(filteredSuggestions)
        .filter(({ key, children }) => children.length),
    [assignedCandidatesFiltered, filteredSuggestions],
  );

  return (
    <>
      <StickyPanel.TitleContainer>
        <Typography.Title level={3}>Candidates</Typography.Title>
      </StickyPanel.TitleContainer>
      <StickyPanel.StickyExtra>
        <InputSearch
          value={searchFilter}
          placeholder="Quick filter"
          onChange={(e) => setSearchFilters(e.target.value)}
        />
        <Button
          size="large"
          className="icon-left"
          onClick={() => setDrawerView('createCandidate')}>
          <Add />
          Add consultant
        </Button>
        <Button
          size="large"
          className="icon-left"
          onClick={() => setSearchDrawerView(viewMode ? null : 'filter')}>
          <Filter />
          Global search
        </Button>
      </StickyPanel.StickyExtra>
      <ExpandableTableHeader columns={columnsTalent} />
      {!!clientGroups.length && (
        <TalentTableWithDrawer
          showHeader={false}
          expandable
          dataSource={clientGroups}
          loading={dataLoading}
          defaultExpandedRowKeys={clientStatuses.filter(
            (s) => s !== CANDIDATE_STATUS.DISMISSED,
          )}
        />
      )}
      {!!internalGroups.length && (
        <TalentTableWithDrawer
          showHeader={false}
          expandable
          dataSource={internalGroups}
          loading={dataLoading}
          defaultExpandedRowKeys={internalStatuses}
        />
      )}
      <TalentTableWithDrawer
        showHeader={false}
        expandable
        dataSource={searchGroups}
        loading={dataLoading}
        defaultExpandedRowKeys={searchStatuses
          // always collapse Dropped by default
          .filter((status) => status !== CANDIDATE_STATUS.ARCHIVED)
          .concat('matches')}
      />
      <TalentDrawer>
        <TalentDrawerContent />
      </TalentDrawer>
      <GlobalSearchDrawer candidates={candidates} />
    </>
  );
};
