import styled from 'styled-components';
import { Layout } from 'antd';

import { AppLayout } from './AppLayout';

const AuthContent = styled(Layout)`
  width: 625px;
  max-width: 100%;
  margin: 0 auto;
`;

export const AuthLayout = ({ children }) => (
  <AppLayout>
    <AuthContent>{children}</AuthContent>
  </AppLayout>
);
