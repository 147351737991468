import { Button, Typography } from 'antd';

import { RequestsSlider } from '@yougig/ui/components/elements/RequestsSlider';
import { TalentProfileLayout } from '@yougig/ui/components/talents/TalentProfileLayout';
import { useTalentDrawerContext } from '@yougig/ui/components/talents/TalentDrawerContext';
import { RequestService } from '@yougig/shared/actions/RequestService';

export const TalentProfileRequests = () => {
  const { profile, job, setDrawerView } = useTalentDrawerContext();
  const requests = RequestService.useTalentRequests(profile, {
    sortByClient: job?.client,
  });

  return (
    <>
      <TalentProfileLayout.Row>
        <Typography.Title level={4}>Requests</Typography.Title>
        <Typography.Text type="secondary">
          To speed up hiring process, you can request additional information or
          book an interview.
        </Typography.Text>
      </TalentProfileLayout.Row>
      <TalentProfileLayout.Row>
        <Button
          type="ghost"
          onClick={(e) => {
            e.stopPropagation();
            setDrawerView('request-interview', { request: null });
          }}>
          Interview
        </Button>
        <Button
          type="ghost"
          onClick={(e) => {
            e.stopPropagation();
            setDrawerView('request-info', { request: null });
          }}>
          Other request
        </Button>
      </TalentProfileLayout.Row>
      {requests.length > 0 && (
        <div className="drawer-section--slider">
          <RequestsSlider
            requests={requests}
            canEdit={() => true}
            onEdit={(request) =>
              setDrawerView(`request-${request.type}`, { request })
            }
          />
        </div>
      )}
    </>
  );
};
