import { Typography } from 'antd';

import { DrawerProvider } from '@yougig/ui/components/common/DrawerProvider';

import { ClientDrawer } from '../../components/clients/ClientDrawer';
import { ClientsTable } from '../../components/clients/ClientsTable';

export const Clients = () => {
  return (
    <DrawerProvider>
      <div className="page-heading">
        <Typography.Title>Clients</Typography.Title>
      </div>
      <ClientsTable />
      <ClientDrawer />
    </DrawerProvider>
  );
};
