import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router';

import { useCandidatesData } from '@yougig/shared/candidates/useCandidatesData';
import { useApiJob } from '@yougig/shared/jobs/useApiJob';

import { db } from '../../utils/firebase';

export const JobContext = createContext({
  candidates: [],
  assignedCandidates: [],
  updateCandidateInList: () => {},
});

export const JobProvider = ({ children }) => {
  const { jobId } = useParams();

  const [job, jobLoading] = useApiJob(jobId);

  const candidatesQuery = useMemo(
    () =>
      job.client &&
      db.collection('candidates').where('client', '==', job.client),
    [job.client],
  );
  const [candidates = [], candidatesLoading, , setCandidates] =
    useCandidatesData(candidatesQuery);

  const updateCandidateInList = useCallback(
    (data, key) => {
      setCandidates(
        candidates.map((entry) =>
          entry.key === key ? { ...entry, ...data } : entry,
        ),
      );
    },
    [candidates, setCandidates],
  );
  const [assignments, setAssignments] = useState([]);
  const [assignmentsLoading, setAssignmentsLoading] = useState(false);
  useEffect(() => {
    if (job.client) {
      setAssignmentsLoading(true);
      return db
        .collectionGroup('assignments')
        .where('client', '==', job.client)
        .onSnapshot((snaps) => {
          if (snaps.size) {
            setAssignments(
              snaps.docs.map((snap) => ({
                id: snap.id,
                candidate: snap.ref.parent.parent.id,
                ...snap.data(),
              })),
            );
          }
          setAssignmentsLoading(false);
        });
    }
  }, [job.client]);

  const assignedCandidates = useMemo(
    () =>
      candidates.filter((c) =>
        assignments.some(
          (a) => a.id === job.key && c.candidate.id === a.candidate,
        ),
      ),
    [candidates, assignments, job],
  );

  const contextValue = useMemo(
    () => ({
      job,
      candidates,
      assignments,
      assignedCandidates,
      dataLoading: assignmentsLoading || candidatesLoading,
      jobLoading,
      updateCandidateInList,
    }),
    [
      job,
      jobLoading,
      candidates,
      assignments,
      assignedCandidates,
      candidatesLoading,
      assignmentsLoading,
      updateCandidateInList,
    ],
  );

  return (
    <JobContext.Provider value={contextValue}>
      {!!job ? children : null}
    </JobContext.Provider>
  );
};

export const useJob = () => useContext(JobContext);
