import { useCallback, useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import styled from 'styled-components';

import { COLORS } from '@yougig/shared/constants';

import { Comment, Edit } from '../../icons';
import { ShowMoreText } from '../elements/ShowMoreText';

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  align-items: flex-start;
  flex-basis: 0;
  flex-grow: 1;
  min-width: 1px;
  .ant-btn {
    padding: 0;
  }
  > .ant-btn {
    margin-left: 16px;
  }
`;

const TextArea = styled(Input.TextArea).attrs({ autoSize: true })`
  margin-bottom: 6px;
`;

const TextShowMoreWrapper = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  min-width: 1px;
`;

const AddNotesBtn = styled(Button)`
  &.ant-btn {
    padding: 0;
    display: inline-flex;
    align-items: center;
  }
  svg {
    margin-right: 8px;
  }
  span.secondary {
    color: ${COLORS.DESCRIPTION};
    margin-left: 5px;
  }
`;

export const NotesField = ({ value, onSave, linesVisible }) => {
  const [text, setText] = useState(value || '');
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setText(value || '');
  }, [value]);

  const onKeyUp = useCallback(
    (e) => {
      // Command+Enter or Ctrl+Enter
      if (e.keyCode === 13 && (e.metaKey || e.ctrlKey)) {
        setEditing(false);
        onSave(text);
      } else if (e.keyCode === 27 /* ESC */) {
        setText(value || '');
        setEditing(false);
      }
    },
    [onSave, text, value],
  );

  const onDone = useCallback(
    (e) => {
      e.stopPropagation();
      setEditing(false);
      // do not save unchanged text and empty text when there was no value before.
      if ((text && text !== value) || text || value) {
        onSave(text);
      }
    },
    [onSave, text, value],
  );

  if (!text && !editing) {
    return (
      <AddNotesBtn
        type="link"
        size="small"
        htmlType="button"
        icon={
          <Comment width={16} height={16} outlined color={COLORS.PRIMARY} />
        }
        onClick={(e) => {
          e.stopPropagation();
          setEditing(true);
        }}>
        Add Notes <span className="secondary">(only managers can see it)</span>
      </AddNotesBtn>
    );
  }

  if (editing) {
    return (
      <Wrapper direction="column">
        <TextArea
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyUp={onKeyUp}
          onBlur={onDone}
        />
        <Button
          type="link"
          htmlType="button"
          aria-label="Done"
          className="ml-auto"
          onClick={onDone}>
          Done
        </Button>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <TextShowMoreWrapper>
        <ShowMoreText
          lines={linesVisible}
          text={text}
          onDoubleClick={() => setEditing(true)}
        />
      </TextShowMoreWrapper>
      <Button
        type="link"
        htmlType="button"
        aria-label="Edit"
        onClick={(e) => {
          e.stopPropagation();
          setEditing(true);
        }}
        icon={<Edit width={16} height={16} />}
      />
    </Wrapper>
  );
};
