export class Update {
  static async create(parentRef, { client, ...payload }) {
    if (client) {
      // store client only if provided.
      payload.client = client;
    }
    return parentRef.collection('updates').add(payload);
  }

  static delete(update) {
    return update.__ref.delete();
  }
}
