import { useTalentDrawerContext } from '@yougig/ui/components/talents/TalentDrawerContext';
import { BookInterview } from '@yougig/ui/components/talents/BookInterview';
import { RequestForm } from '@yougig/ui/components/talents/RequestForm';

import { TalentProfile } from './TalentProfile';
import { EditCandidate } from './EditCandidate';
import { CreateCandidate } from './CreateCandidate';
import { CandidateChat } from './CandidateChat';
import { TalentReport } from './TalentReport';

export const TalentDrawerContent = () => {
  const { profile, viewMode } = useTalentDrawerContext();

  switch (viewMode) {
    case 'profile':
      return profile ? <TalentProfile /> : null;
    case 'request-interview':
      return <BookInterview />;
    case 'request-info':
      return <RequestForm />;
    case 'createCandidate':
      return <CreateCandidate />;
    case 'editCandidate':
      return <EditCandidate />;
    case 'chat':
      return (
        <CandidateChat profileRef={profile.ref} fullName={profile.fullName} />
      );
    case 'report':
      return <TalentReport />;
    default:
      return null;
  }
};
