import {
  object,
  string,
  array,
  date,
  mixed,
} from '@yougig/shared/utils/validation';

import { REQUEST_AUTHOR } from '../actions/constants';

export const schema = object({
  authorType: string().oneOf(Object.values(REQUEST_AUTHOR)),
  dateTime: date().required(),
  notes: string(),
  attachments: array().nullable(),
  position: string().nullable(),
  client: mixed().when('authorType', {
    is: REQUEST_AUTHOR.CLIENT,
    then: object().nullable().required(),
    otherwise: object().nullable(),
  }),
});
