import { Form, Slider } from 'formik-antd';
import styled from 'styled-components';

const SliderStyled = styled(Slider)`
  .ant-slider-dot,
  .ant-slider-rail {
    background-color: var(--c_primary) !important;
  }
  .ant-slider-track,
  .ant-slider-dot-active {
    background-color: var(--c_light_blue) !important;
  }
`;

export const ScoringSlider = ({ name, value, label }) => {
  return (
    <Form.Item name={name} label={label}>
      <div className="form-item--suffix">
        {value === 0 ? 'any' : `${value} and higher`}
      </div>
      <SliderStyled name={name} dots max={5} tooltipVisible={false} />
    </Form.Item>
  );
};
