import { Typography, Button } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import styled from 'styled-components';

import { Card } from '@yougig/ui/components/elements/Card';
import { object, string } from '@yougig/shared/utils/validation';
import { useAuth } from '@yougig/shared/auth/AuthProvider';

const FormErrorContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const FormContainer = styled.div`
  margin: 40px auto;
  max-width: 400px;
`;

const FormFooter = styled.div`
  text-align: right;
`;

const schema = object({
  email: string().email().required(),
  password: string().required(),
});

export const LoginPage = () => {
  const { authorize } = useAuth();
  return (
    <>
      <div className="page-heading">
        <Typography.Title>Login</Typography.Title>
      </div>
      <Card>
        <FormContainer>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={schema}
            onSubmit={async (values, { setStatus, setSubmitting }) => {
              try {
                await authorize(values);
                setSubmitting(false);
              } catch (err) {
                setStatus(err.message);
              }
            }}>
            {({ status, handleSubmit, isSubmitting }) => (
              <>
                <Form onSubmit={handleSubmit} layout="vertical">
                  <Form.Item name="email" label="Email">
                    <Input
                      type="email"
                      name="email"
                      placeholder="email@example.com"
                    />
                  </Form.Item>
                  <Form.Item name="password" label="Password">
                    <Input
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                  </Form.Item>
                  {!!status && (
                    <FormErrorContainer>
                      <Typography.Text type="danger">{status}</Typography.Text>
                    </FormErrorContainer>
                  )}
                  <FormFooter>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isSubmitting}>
                      Sign In
                    </Button>
                  </FormFooter>
                </Form>
              </>
            )}
          </Formik>
        </FormContainer>
      </Card>
    </>
  );
};
