import { useMemo, useState, useEffect } from 'react';
import { Button, Dropdown, Menu } from 'antd';

import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { Table } from '@yougig/ui/components/elements/Table';
import { baseColumns } from '@yougig/ui/utils/client-columns';
import { MoreDots } from '@yougig/ui/icons';
import { CHAT_TYPES } from '@yougig/shared/chat/constants';
import { Chat } from '@yougig/shared/chat/Chat';
import { lastMessageColumn } from '@yougig/ui/components/chat/lastMessageColumn';
import { useImpersonate } from '@yougig/ui/utils/useImpersonate';

import { db } from '../../utils/firebase';

const ImpersonateButton = ({ uid }) => {
  const impersonate = useImpersonate(uid, process.env.REACT_APP_CLIENT_APP_URL);

  return (
    <Dropdown
      type="ghost"
      placement="bottomRight"
      trigger="click"
      getPopupContainer={(el) => el.parentElement}
      overlay={
        <Menu>
          <Menu.Item key="impersonate" onClick={impersonate}>
            Impersonate
          </Menu.Item>
        </Menu>
      }>
      <Button
        type="link"
        size="small"
        icon={<MoreDots />}
        onClick={(e) => e.stopPropagation()}
      />
    </Dropdown>
  );
};

const ChatButton = ({ client }) => {
  const { setDrawerView } = useDrawerContext();
  return (
    <Button
      type="ghost"
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        setDrawerView('chat', { client });
      }}>
      Chat
    </Button>
  );
};

const clientCmp = (c1, c2) => {
  if (!c1.manager_weight) {
    return 1;
  }
  if (!c2.manager_weight) {
    return -1;
  }
  let i = 0;
  while (i < Math.min(c1.manager_weight.length, c2.manager_weight.length)) {
    if (c1.manager_weight[i] === c2.manager_weight[i]) {
      i++;
      continue;
    }
    return c2.manager_weight[i] - c1.manager_weight[i];
  }
};

export const ClientsTable = () => {
  const { client, setDrawerView } = useDrawerContext();
  const { isAdmin } = useAuth();

  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return db.collection('clients').onSnapshot(async (snaps) => {
      const data = await Promise.all(
        snaps.docs.map(async (snap) => {
          const entry = {
            id: snap.id,
            __ref: snap.ref,
            ...snap.data(),
          };
          const owners = await snap.ref.collection('users').get();
          if (owners.size) {
            const owner = await (
              owners.docs.find((o) => o.get('role') === 'owner') ||
              owners.docs[0]
            )
              .get('user')
              .get();
            entry.owner = {
              id: owner.id,
              ...owner.data(),
            };

            const chatSnaps = await Chat.getChatQuery(
              CHAT_TYPES.CLIENT,
              entry.__ref,
            ).get();

            if (chatSnaps.size) {
              const chat = Chat.fromSnapshot(chatSnaps.docs[0]);
              const lastMessageSnaps = await chat.getLastMessageQuery().get();
              if (lastMessageSnaps.size) {
                const lastMessageSnap = lastMessageSnaps.docs[0];
                entry.lastMessage = {
                  id: lastMessageSnap.id,
                  ...lastMessageSnap.data(),
                  dateTime: lastMessageSnap.get('dateTime').toDate(),
                };
              }
            }
          }

          return entry;
        }),
      ).catch((err) => console.log(err));
      setClients(data.sort(clientCmp));
      setLoading(false);
    });
  }, [setClients, setLoading]);

  const columns = useMemo(() => {
    return baseColumns.concat([
      {
        title: 'Actions',
        className: 'cell-actions-stat',
        dataIndex: 'manager_weight',
        render: (manager_weight) => {
          if (!manager_weight) {
            return null;
          }
          const [reports, requests] = manager_weight;
          return (
            <span>
              {reports} report{reports > 1 && 's'}
              <br />
              {requests} request{requests > 1 && 's'}
            </span>
          );
        },
      },
      lastMessageColumn,
      {
        title: '',
        className: 'actions',
        key: 'actions',
        width: 120,
        render: (_, record) => {
          if (!record.owner) {
            return null;
          }
          return (
            <>
              <ChatButton client={record} />
              {isAdmin && <ImpersonateButton uid={record.owner.id} />}
            </>
          );
        },
      },
    ]);
  }, [isAdmin]);

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={clients}
      rowClassName={(record) =>
        !record.children &&
        client &&
        client.id === record.id &&
        'ant-table-row--selected'
      }
      rowKey={(row) => row.id}
      pagination={false}
      onRow={(record) => ({
        onClick: () => {
          setDrawerView('details', { client: record });
        },
      })}
    />
  );
};
