import { useHistory } from 'react-router';
import { useEffect } from 'react';

import {
  MainLayout,
  MainContent,
} from '@yougig/ui/components/layout/MainContent';

import { Header } from './Header';

export const AppLayout = ({ children }) => {
  const history = useHistory();
  useEffect(() => history.listen(() => window.scrollTo(0, 0)), [history]);

  return (
    <MainLayout id="main-container">
      <Header />
      <MainContent>{children}</MainContent>
    </MainLayout>
  );
};
