import { useCallback, useMemo } from 'react';
import {
  Form as AntForm,
  Divider,
  Typography,
  Button,
  Row,
  Popover,
} from 'antd';
import { Form, Input, Switch } from 'formik-antd';
import { Formik } from 'formik';

import { array, object, string } from '@yougig/shared/utils/validation';

import { LinksField } from '../../../components/resources/LinksField';

export const schema = object({
  internalNotes: string(),
  screeningUrl: string().url(),
  internalLinks: array().of(
    object({
      url: string().url(),
      notes: string(),
    }),
  ),
});

export const AssignmentForm = ({ assignment, onClose }) => {
  const initialValues = useMemo(
    () => ({
      internalLinks: assignment.internalLinks || [],
      internalNotes: assignment.internalNotes || '',
      screeningUrl: assignment.screeningUrl || '',
      public: assignment.public || false,
    }),
    [assignment],
  );

  const onSubmit = useCallback(
    async ({ internalLinks, ...values }, { setSubmitting }) => {
      const isDraft = assignment.isDraft;
      await assignment.save({
        internalLinks: internalLinks.filter((link) => !!link.url),
        ...values,
      });
      setSubmitting(false);
      if (isDraft) {
        window.location.reload();
      } else {
        onClose();
      }
    },
    [assignment, onClose],
  );

  const publicJobPopup = (
    <span>
      Switch on if you want this job to be published on our website to get more
      candidates suggested
    </span>
  );

  return (
    <div className="assignment-form">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={schema}
        onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting }) => (
          <Form layout="vertical">
            <Row justify="space-between">
              <Typography.Title level={3}>
                {assignment.external_id ? 'Edit' : 'Create'}
              </Typography.Title>
              <Popover
                content={publicJobPopup}
                arrowPointAtCenter
                placement="bottomLeft">
                <Form.Item
                  name="public"
                  label="Public job"
                  className="assignment-form__public-job">
                  <Switch name="public" size="small" />
                </Form.Item>
              </Popover>
            </Row>
            <Form.Item name="screeningUrl" label="Screening questions">
              <Input
                type="url"
                name="screeningUrl"
                placeholder="Link to Google Forms"
              />
            </Form.Item>
            <AntForm.Item
              label="Internal Links"
              className="col-12 links--field">
              <LinksField name="internalLinks" />
            </AntForm.Item>
            <Divider />
            <Form.Item name="internalNotes" label="Internal Notes">
              <Input.TextArea
                autoSize
                name="internalNotes"
                placeholder="Enter some text here"
              />
            </Form.Item>
            <AntForm.Item className="form-actions">
              <Button type="ghost" htmlType="button" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="ml-auto"
                disabled={isSubmitting}
                onClick={handleSubmit}>
                Save
              </Button>
            </AntForm.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};
