import { useCallback, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { notification } from 'antd';

import { AuthProvider } from '@yougig/shared/auth/AuthProvider';
import { ApiProvider } from '@yougig/shared/api/Api';
import { QueryProvider } from '@yougig/shared/common/Query';
import { setupThemeVariables } from '@yougig/ui/utils/themes';
import { Close } from '@yougig/ui/icons';

import Routing from './pages';

function App() {
  useEffect(() => {
    setupThemeVariables();
  }, []);

  const handleApiError = useCallback((error) => {
    if (error.isAxiosError) {
      notification.error({
        message: 'API issue',
        description:
          'Something went wrong on the API side. Please, check again later or report.',
        duration: 0,
        closeIcon: <Close width={16} height={16} />,
      });
    }
    console.log('error', error);
  }, []);

  return (
    <Router>
      <AuthProvider>
        <ApiProvider baseUrl={process.env.REACT_APP_API_URL}>
          <QueryProvider onError={handleApiError}>
            <Routing />
          </QueryProvider>
        </ApiProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
