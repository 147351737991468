import { useMemo } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';

import { Lock } from '@yougig/ui/icons';
import { LinksList } from '@yougig/ui/components/elements/LinksList';
import { NotesField } from '@yougig/ui/components/talents/NotesField';
import { UPDATES_TYPE } from '@yougig/shared/updates/constants';

import { Updates } from '../../../components/common/updates/Updates';

const LockSection = styled.div`
  position: relative;
  background-color: var(--c_ghost_white);
  padding-top: 48px;
  padding-bottom: 40px;
  margin-bottom: 28px;
  z-index: 31;
  &:before {
    position: absolute;
    content: '';
    left: calc(50% - 23px);
    border-width: 0 23px 23px 23px;
    border-style: solid;
    border-color: transparent transparent var(--c_ghost_white) transparent;
    top: -23px;
    pointer-events: none;
  }
  &:after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    background-color: var(--c_ghost_white);
    z-index: -1;
  }
  > svg {
    position: absolute;
    left: 50%;
    top: 0;
    width: 24px;
    height: 24px;
    transform: translateX(-50%);
    z-index: 1;
  }
`;

const LockSectionCol = styled(Col)`
  flex: 0 0 30%;
  max-width: 30%;
  margin-right: 7.5%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media (max-width: 767px) {
    min-width: 100%;
    margin: 0 0 32px;
  }
`;

const LinksListRow = styled.div`
  display: flex;
  gap: 10px;
  > .ant-btn {
    margin-left: auto;
  }
`;

/**
 *
 * @param {Object} props
 * @param {import("@yougig/shared/jobs/Job").Job} props.job Job instance
 * @returns
 */
export const JobDetailsLockInfo = ({ job }) => {
  const sourceUrl = job?.source?.url;
  const links = useMemo(
    () =>
      [
        {
          url: `${process.env.REACT_APP_TALENT_APP_URL}jobs/${job.key}`,
          label: 'Job page',
        },
        job.screeningUrl
          ? { url: job.screeningUrl, label: 'Apply form' }
          : null,
      ]
        .concat(
          job.internalLinks?.length
            ? job.internalLinks.map(({ url, notes }) => ({
                url,
                label: notes,
              }))
            : [],
        )
        .concat(sourceUrl && [{ url: sourceUrl, label: 'Source' }])
        .filter(Boolean),
    [job.screeningUrl, job.internalLinks, job.key, sourceUrl],
  );

  return (
    <LockSection>
      <Lock className="drawer-section__icon" />
      <Row>
        <LockSectionCol>
          {!!links?.length && (
            <LinksListRow>
              <LinksList links={links} />
              {/* <Button type="link" icon={<Paperclip />} /> */}
            </LinksListRow>
          )}
          <NotesField
            value={job.internalNotes}
            linesVisible={10}
            onSave={async (value) => {
              await job.save({ internalNotes: value });
            }}
          />
        </LockSectionCol>
        {job?.ref && (
          <Col flex="1 1 62.5%">
            <Updates parentRef={job.ref} type={UPDATES_TYPE.JOB} />
          </Col>
        )}
      </Row>
    </LockSection>
  );
};
