import { useCallback, useMemo } from 'react';
import { Dropdown, Menu, Button } from 'antd';
import uniq from 'lodash/uniq';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { JobStatusButton } from '@yougig/ui/components/assignments/JobStatusButton';
import { MoreDots } from '@yougig/ui/icons';
import { Modal } from '@yougig/ui/components/elements/Modal';
import { TalentActionsBar } from '@yougig/ui/components/talents/TalentActionsBar';
import { CheckList } from '@yougig/ui/components/assignments/CheckList';
import {
  CANDIDATE_STATUS,
  MANAGER_CHECKLIST,
} from '@yougig/shared/candidates/constants';
import { useTalentDrawerContext } from '@yougig/ui/components/talents/TalentDrawerContext';

import { useJob } from '../../pages/jobs/JobContext';

export const TalentActions = () => {
  const { userRef } = useAuth();
  const { candidate, entryKey, profile, setDrawerView } =
    useTalentDrawerContext();
  const {
    assignments: candidateAssignments,
    job,
    updateCandidateInList,
  } = useJob();

  const [client] = useDocumentData(job.client, {
    refField: 'ref',
    idField: 'id',
  });

  const candidateAssignment = useMemo(
    () =>
      candidateAssignments.find(
        (a) => a.candidate === candidate?.id && a.id === job.key,
      ),
    [job, candidate, candidateAssignments],
  );

  const assignedJobs = useMemo(
    () =>
      uniq(
        candidateAssignments
          .filter((a) => a.candidate === candidate?.id)
          .map((a) => a.id)
          .concat(job.id),
      ),
    [candidateAssignments, candidate?.id, job.id],
  );

  const addToJob = useCallback(async () => {
    // since we don't need to change status of the candidate but just add another
    // assignment, we are assigning the same status it already has but with
    // another position (job) in the context.
    await candidate.setStatusAndSave(candidate.status, {
      position: job,
      profile,
      userRef,
    });
    updateCandidateInList({ candidate }, entryKey);
  }, [job, userRef, candidate, updateCandidateInList, entryKey, profile]);

  const onQuickAdd = useCallback(async () => {
    await candidate.setStatusAndSave(CANDIDATE_STATUS.INCOMING, {
      client: client?.ref,
      profile,
      userRef,
      position: job,
    });
    updateCandidateInList({ candidate }, entryKey);
  }, [
    job,
    client,
    profile,
    userRef,
    candidate,
    updateCandidateInList,
    entryKey,
  ]);

  const isChecklistCompleted = useMemo(() => {
    return (
      Object.values(candidateAssignment?.checklist || {})
        .concat(candidateAssignment?.applied || false)
        .filter(Boolean).length === MANAGER_CHECKLIST.length
    );
  }, [candidateAssignment]);

  const onRemoveConfirm = useCallback(
    () =>
      Modal.confirm({
        title: 'Are you sure you want to remove from Job?',
        onOk() {
          return candidate.removeAssignment(candidateAssignment.id);
        },
      }),
    [candidate, candidateAssignment],
  );

  return (
    <TalentActionsBar>
      <div>
        <strong>Job: </strong>
        <span>{job.name}</span>
        {!!candidateAssignment && (
          <CheckList assignment={candidateAssignment} candidate={candidate} />
        )}
      </div>
      <TalentActionsBar.Buttons>
        {candidate.status ? (
          <JobStatusButton
            candidate={candidate}
            isChecklistCompleted={isChecklistCompleted}
            assignedJobs={assignedJobs}
            profile={profile}
            client={client}
            onRemove={() => setDrawerView(null)}
          />
        ) : (
          <Button type="primary" onClick={onQuickAdd}>
            Quick Add
          </Button>
        )}
        {!!candidate.status && (
          <Dropdown
            type="ghost"
            placement="topRight"
            trigger="click"
            overlayClassName="ant-dropdown--fixed"
            overlay={
              <Menu>
                {candidateAssignment ? (
                  <Menu.Item key="remove" onClick={onRemoveConfirm}>
                    Remove from Job
                  </Menu.Item>
                ) : (
                  <Menu.Item key="add" onClick={addToJob}>
                    Add to Job
                  </Menu.Item>
                )}
              </Menu>
            }>
            <Button type="ghost" icon={<MoreDots />} />
          </Dropdown>
        )}
      </TalentActionsBar.Buttons>
    </TalentActionsBar>
  );
};
