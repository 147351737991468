import { useMemo } from 'react';
import { Formik } from 'formik';
import { Typography } from 'antd';

import { Attachments } from '@yougig/shared/common/Attachments';
import { useTalentDrawerContext } from '@yougig/ui/components/talents/TalentDrawerContext';

import { CandidateForm, schema, transformFormPayload } from './CandidateForm';
import { useJob } from '../../pages/jobs/JobContext';

export const EditCandidate = () => {
  /**
   * @type {import('@yougig/ui/components/talents/TalentDrawerContext').TalentDrawerContextValue}
   */
  const { candidate, profile, entryKey, setDrawerView } =
    useTalentDrawerContext();
  const { updateCandidateInList } = useJob();

  const initialValues = useMemo(() => {
    if (!profile) {
      return {};
    }
    const {
      id,
      worked_with_us,
      vetted,
      title = '',
      availability = {},
      ...values
    } = profile.firebaseData;

    if (values.languages?.length === 1 && !values.languages[0].language) {
      delete values.languages;
    }

    return {
      ...values,
      title: title,
      verification: vetted ? (worked_with_us ? 'proven' : 'vetted') : '',
      attachments: (profile.attachments || []).map(Attachments.toRcUpload),
      internalAttachments: (profile.internalAttachments || []).map(
        Attachments.toRcUpload,
      ),
      availability: {
        ...availability,
        availability: availability.availability || '',
        fromDate: availability.fromDate || null,
        untilDate: availability.untilDate || null,
        notAvailableUntilDate: availability.notAvailableUntilDate || null,
      },
    };
  }, [profile]);

  return (
    <div className="drawer-section">
      <Typography.Title level={3}>Edit Candidate</Typography.Title>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={schema}
        onSubmit={async (values, { setSubmitting }) => {
          const payload = transformFormPayload(values);
          await profile.save(payload);
          setSubmitting(false);
          setDrawerView('profile', { profile });
          updateCandidateInList({ profile }, entryKey);
        }}>
        <CandidateForm
          candidate={candidate}
          onCancel={() => setDrawerView('profile')}
        />
      </Formik>
    </div>
  );
};
