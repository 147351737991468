import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Col, Row } from 'antd';

import services from '@yougig/shared/utils/services';
import {
  CANDIDATE_STATUS,
  CANDIDATE_STATUS_OPTIONS,
} from '@yougig/shared/candidates/constants';
import { StatusLabel } from '@yougig/ui/components/elements/Status';
import { ClientAutoComplete } from '@yougig/ui/components/form/ClientAutoComplete';
import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { Candidate } from '@yougig/shared/candidates/Candidate';
import { JobStatusButton } from '@yougig/ui/components/assignments/JobStatusButton';
import { useTalentDrawerContext } from '@yougig/ui/components/talents/TalentDrawerContext';

const useClients = (profile) => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    return services
      .get('db')
      .collection('candidates')
      .where('consultant', '==', profile.ref)
      .withConverter(Candidate.converter)
      .onSnapshot(async (snaps) => {
        const res = await Promise.all(
          snaps.docs.map(async (snap) => {
            const candidate = snap.data();
            const { status, client } = candidate;
            const clientSnap = await client.get();
            const clientData = {
              ...clientSnap.data(),
              ref: clientSnap.ref,
              id: clientSnap.id,
            };
            const jobs = await snap.ref.collection('assignments').get();
            const assignedJobs = jobs.docs.map((jobSnap) => jobSnap.id);

            const requests = await services
              .get('db')
              .collection('actions')
              .where('consultant', '==', profile.ref)
              .where('client', '==', client)
              .get();

            return {
              candidate,
              assignedJobs,
              status,
              client: clientData,
              jobs: jobs.size,
              requests: requests.size,
            };
          }),
        );
        setClients(res);
      });
  }, [profile]);

  return clients;
};

const ClientsList = styled.div`
  margin: 17px 0 24px 0;
  .ant-row {
    margin-bottom: 40px;
  }
`;

const ClientInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StatusLabelStyled = styled(StatusLabel)`
  font-size: 14px;
  line-height: inherit;
  padding: 0;
`;

const ClientInfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  line-height: 1.2;
  .name {
    font-weight: 600;
  }
`;

export const TalentClientsTab = () => {
  const { profile } = useTalentDrawerContext();
  const data = useClients(profile);
  const { userRef } = useAuth();

  const addNewClient = useCallback(
    async (client) => {
      if (!!client && !data.find((o) => o.client.id === client.id)) {
        const newCandidate = new Candidate({
          consultant: profile.ref,
          name: profile.firstName,
          client: client,
        });
        await newCandidate.setStatusAndSave(CANDIDATE_STATUS.INCOMING, {
          userRef,
        });
      }
    },
    [userRef, profile, data],
  );

  return (
    <ClientsList>
      <ClientAutoComplete onChange={(client) => addNewClient(client)} />
      {data.map(
        ({ status, jobs, requests, candidate, assignedJobs, client }) => {
          const { background, ...statusProps } =
            CANDIDATE_STATUS_OPTIONS.get(status) || {};
          return (
            <Row justify="space-between" align="middle" key={client.id}>
              <Col>
                <ClientInfo>
                  <ClientInfoItem>
                    <span className="name">{client.businessName}</span>
                    <StatusLabelStyled {...statusProps} />
                  </ClientInfoItem>
                  <ClientInfoItem>
                    <span>
                      {jobs} job{jobs > 1 && 's'}
                    </span>
                    <span>
                      {requests} open request{requests > 1 && 's'}
                    </span>
                  </ClientInfoItem>
                </ClientInfo>
              </Col>
              <Col>
                <JobStatusButton
                  candidate={candidate}
                  assignedJobs={assignedJobs}
                  profile={profile}
                  client={client}>
                  <Button type="ghost" size="small">
                    Manage
                  </Button>
                </JobStatusButton>
              </Col>
            </Row>
          );
        },
      )}
    </ClientsList>
  );
};
